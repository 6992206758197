import { BuilderFunction } from "../../../common/src/dom";
import { Event, Ref } from "../../../common/src/react";
import { getChildren } from "../../../common/src/utils";

export function getLargestSlideWidth(slides: HTMLElement[]): number {
  return Math.max(...slides.map((slide) => slide.getBoundingClientRect().width));
}

/**
 *
 *
 *
 *
 */
export function computeObserverThreshold(
  basis: number,
  largestSlideWidth: number,
  viewPortWidth: number
): number {
  return viewPortWidth >= largestSlideWidth * basis ? basis : viewPortWidth / largestSlideWidth;
}

type GetObserverThresholdData = {
  /**
 *
 */
  thresholdBasis: Ref<number>;
  /**
 *
 */
  refreshTrigger: Event<[]>;
  /**
 *
 */
  intersectionThreshold: Ref<number>;
};

function updateObserverThreshold(data: GetObserverThresholdData, stage: HTMLElement): void {
  /*                                                              */

  const slides = getChildren<HTMLElement>(stage);
  const largestSlideWidth = getLargestSlideWidth(slides);

  data.intersectionThreshold.value = computeObserverThreshold(
    data.thresholdBasis.value,
    largestSlideWidth,
    stage.clientWidth
  );
}

export function domObserverThreshold(data: GetObserverThresholdData): BuilderFunction<HTMLElement> {
  return (element) => {
    data.refreshTrigger.sub(() => updateObserverThreshold(data, element));
  };
}
