import { AnyChildElement, AnyParentElement, ElementBuilder, HasMountHooks } from "./builder";

/*                                                          */
export type AnyChild<E extends AnyChildElement = any> = Node | DocumentFragment | ElementBuilder<E>;

export function notifiyMount(element: AnyParentElement, child: HasMountHooks | Node): void {
  if ("onMount" in child) {
    child.onMount(element);
  }
}

export function notifiyUnmount(element: AnyParentElement, child: HasMountHooks | Node): void {
  if ("onUnmount" in child) {
    child.onUnmount();
  }
}
