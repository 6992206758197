import { EmptyRecord } from "../definitions";
import { domCreate, ElementBuilder } from "../dom";
import { getElementBuilder } from "../dom/builder.internal";
import { getElementToBuilder } from "../dom/internal";
import { defineProperty, waitForRepaint } from "../utils";
import { Component } from "./component";
import { DomContext } from "./context";
import { ComponentDefinition } from "./definition";
import { AnyEmits, ComponentEmits, createComponentEmits } from "./events";
import { AnyProps, createComponentProps, InputProps, Properties } from "./props";
import { componentToElement, elementToComponent } from "./utils";

/**
 *
 *
 *
 *
 *
 */
export type ComponentConstructor<
  Name extends string,
  Root extends HTMLElement,
  Props extends AnyProps,
  Emits extends AnyEmits
> = (
  props: { [P in keyof InputProps<Props>]: InputProps<Props>[P] },
  root?: Root | ElementBuilder<Root>
) => ElementBuilder<
  Component<
    Name,
    Root,
    { [P in keyof Properties<Props>]: Properties<Props>[P] },
    ComponentEmits<Emits>
  >
>;

/**
 *
 *
 *
 *
 *
 *
 */
export function constructComponent<
  Name extends string,
  Root extends HTMLElement,
  Props extends AnyProps,
  Data extends EmptyRecord,
  Emits extends AnyEmits
>(
  componentDef: ComponentDefinition<Name, Root, Props, Data, Emits>,
  inputProps: InputProps<Props>,
  existingRoot?: Root | ElementBuilder<Root>
): ElementBuilder<Component<Name, Root, Properties<Props>, ComponentEmits<Emits>>> {
  type Context = DomContext<Name, Root, Props, Data, Emits>;

  const {
    name,
    dom,
    root: rootDef = { tag: "div", type: HTMLDivElement },
    props: propsDef = {} as Props,
    setup,
    mount,
    unmount,
    emits: emitsDef = [],
    created,
  } = componentDef;

  const componentContext = { name } as Context;

  /*                                */
  const rootBuilder = (
    existingRoot ? getElementToBuilder(existingRoot) : domCreate(rootDef.tag)
  ) as ElementBuilder<Component<Name, Root, Properties<Props>, ComponentEmits<Emits>>>;

  const rootElement = rootBuilder.element;

  elementToComponent.set(rootElement, componentContext);
  componentToElement.set(componentContext, rootElement);

  /*          */
  componentContext.props = createComponentProps(propsDef, inputProps);

  /*                  */
  componentContext.emit = createComponentEmits(componentContext, emitsDef);

  /*                                   */
  componentContext.data = setup?.(componentContext) ?? ({} as Data);

  /*                                       */
  dom?.({ ...componentContext, root: rootBuilder });

  rootElement.onMount = function onMount() {
    const context = elementToComponent.get(this) as Context;
    const builder = getElementBuilder(this);
    mount && waitForRepaint(mount, 3, { ...context, root: builder });
  };

  rootElement.onUnmount = function onUnmount() {
    const context = elementToComponent.get(this) as Context;
    const builder = getElementBuilder(this);
    unmount?.({ ...context, root: builder });
  };

  /*                                                                   */
  defineProperty(rootElement, "componentName", { writable: false, value: name });

  /*                                                     */
  defineProperty(rootElement, "props", {
    get() {
      return elementToComponent.get(this)?.props;
    },
  });

  /*                                          */
  created?.({ ...componentContext, root: rootBuilder });

  if (rootElement.parentElement) {
    rootElement.onMount(rootElement.parentElement);
  }

  return rootBuilder;
}

/**
 *
 *
 *
 *
 */
export function getComponentConstructor<
  Name extends string,
  Root extends HTMLElement,
  Props extends AnyProps,
  Data extends EmptyRecord,
  Emits extends AnyEmits
>(
  componentDef: ComponentDefinition<Name, Root, Props, Data, Emits>
): ComponentConstructor<Name, Root, Props, Emits> {
  return (props, root) =>
    constructComponent<Name, Root, Props, Data, Emits>(
      componentDef,
      props as InputProps<Props>,
      root
    );
}
