import { AnyChildElement, AnyParentElement, domAppend, domQuerySafe, ElementBuilder } from "../dom";
import { getElementToBuilder } from "../dom/internal";
import { AnyComponent } from "./component";
import { AnyComponentContext } from "./context";

export const elementToComponent = new WeakMap<HTMLElement, AnyComponentContext>();
export const componentToElement = new WeakMap<AnyComponentContext, HTMLElement>();

export function domMap<E extends AnyChildElement, P extends AnyParentElement>(
  selector: string,
  parent: P | ElementBuilder<P> | undefined,
  builder: () => ElementBuilder<E>
): ElementBuilder<E> {
  if (!parent) {
    return builder();
  }

  const existEl = domQuerySafe(selector, parent);
  if (existEl.element) {
    return existEl as never;
  }

  const newEl = builder();
  getElementToBuilder(parent)(domAppend(newEl));

  return newEl;
}

export function domMapComponent<
  E extends AnyChildElement = HTMLElement,
  P extends AnyParentElement = AnyParentElement,
  C extends AnyComponent = AnyComponent
>(
  selector: string,
  parent: ElementBuilder<P>,
  component: (element: ElementBuilder<E> | undefined) => C
): C {
  const root = domQuerySafe(selector, parent);
  const res = component(root.element ? (root as never) : undefined);
  return res;
}
