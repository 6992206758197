import { trackingContext, Derivative, refRegistry, getFromRegistry } from "./internal";
import { Watch } from "./watch";
import * as privates from "./privates";
import { getRefImpl } from "./ref";

type Watcher = () => void;

interface WatchImpl extends Derivative {
  [privates.subscriber]: Watcher;
}

const getImpl = getFromRegistry as (ref: Watch) => WatchImpl;

function unwatch(this: Watch): void {
  const impl = getImpl(this);
  impl[privates.deps].forEach((d) => {
    const depImpl = getRefImpl(d);
    depImpl[privates.unsub](d, impl[privates.subscriber], { thisVal: this });
  });
}

/**
 *
 *
 *
 *
 *
 *
 */
export function watchEffect(watcher: Watcher): Watch {
  const res: Watch = { unwatch };

  const impl: WatchImpl = {
    [privates.deps]: new Set(),
    [privates.subscriber]: watcher,
  };

  refRegistry.set(res, impl);

  /*                           */
  trackingContext.push(impl);
  /*                                      */
  watcher();
  /*                              */
  trackingContext.splice(trackingContext.indexOf(impl), 1);

  impl[privates.deps].forEach((d) => {
    const depImpl = getRefImpl(d);
    return depImpl[privates.sub](d, impl[privates.subscriber], { thisVal: res });
  });

  return res;
}
