import type { EmptyRecord } from "../definitions";
import { AnyRef } from "../react";
import type { AnyParentElement, BuilderFunction } from "./builder";
import { applyOrSubscribe } from "./internal";

export type ElementToEventMap<E extends EventTarget> = E extends HTMLElement | Document
  ? HTMLElementEventMap
  : E extends Element
  ? ElementEventMap
  : string;

/**
 *
 */
export type EventListenerFunction<E extends AnyParentElement, EV> = (this: E, ev: EV) => void;

function setListener<
  E extends Element | ParentNode,
  K extends keyof ElementToEventMap<E> | string,
  EV = K extends keyof ElementToEventMap<E> ? ElementToEventMap<E>[K] : Event
>(
  element: E,
  type: K,
  listenerCb: EventListenerFunction<E, EV>,
  options?: boolean | AddEventListenerOptions
): E {
  element.addEventListener(type as string, listenerCb as EventListener, options);
  return element;
}

/**
 *
 *
 *
 *
 *
 *
 */
export function domAddListener<
  E extends Element | ParentNode,
  K extends keyof ElementToEventMap<E>,
  EV = K extends keyof ElementToEventMap<E> ? ElementToEventMap<E>[K] : Event
>(
  type: K,
  listenerCb: EventListenerFunction<E, EV>,
  options?: boolean | AddEventListenerOptions
): BuilderFunction<E> {
  return (element) => setListener(element, type, listenerCb, options);
}

/**
 *
 *
 *
 *
 *
 *
 *
 */
export function domAddListenerCustom<E extends Element>(
  type: string,
  listenerCb: EventListenerFunction<E, Event>,
  options?: boolean | AddEventListenerOptions
): BuilderFunction<E> {
  return (element) => setListener(element, type, listenerCb, options);
}

export function domDispatch<E extends Element, EV extends Event>(event: EV): BuilderFunction<E> {
  return (element) => {
    element.dispatchEvent(event);
    return element;
  };
}

export function domDispatchCustom<E extends Element, D extends EmptyRecord>(
  type: string,
  initOrEvent?: CustomEvent<D> | CustomEventInit<D>
): BuilderFunction<E> {
  return (element) => {
    element.dispatchEvent(
      initOrEvent instanceof Event ? initOrEvent : new CustomEvent(type, initOrEvent)
    );
    return element;
  };
}

/**
 *
 */
export function domSuppresEvent<E extends Element, K extends keyof ElementToEventMap<E>>(
  type: K
): BuilderFunction<E> {
  return domAddListener(type, (e) => (e as Event).preventDefault());
}

function unsetListener<
  E extends Element | ParentNode,
  K extends keyof ElementToEventMap<E> | string,
  EV = K extends keyof ElementToEventMap<E> ? ElementToEventMap<E>[K] : Event
>(
  element: E,
  type: K,
  listenerCb: EventListenerFunction<E, EV>,
  options?: boolean | AddEventListenerOptions
): E {
  element.removeEventListener(type as string, listenerCb as EventListener, options);
  return element;
}

/**
 *
 *
 *
 *
 *
 *
 */
export function domRemoveListener<
  E extends Element | ParentNode,
  K extends keyof ElementToEventMap<E>,
  EV = K extends keyof ElementToEventMap<E> ? ElementToEventMap<E>[K] : Event
>(
  type: K,
  listenerCb: EventListenerFunction<E, EV>,
  options?: boolean | AddEventListenerOptions
): BuilderFunction<E> {
  return (element) => unsetListener(element, type, listenerCb, options);
}

export function domListener<
  E extends Element,
  K extends keyof ElementToEventMap<E> | string,
  EV = K extends keyof ElementToEventMap<E> ? ElementToEventMap<E>[K] : Event
>(
  refVal: AnyRef | boolean | null | undefined,
  type: K,
  listenerCb: EventListenerFunction<E, EV>,
  options?: boolean | AddEventListenerOptions
): BuilderFunction<E> {
  return (element) => {
    applyOrSubscribe(refVal, (to) => {
      if (to === null || to === false) {
        unsetListener(element, type, listenerCb, options);
      } else if (to !== undefined) {
        setListener(element, type, listenerCb, options);
      }
    });
    return element;
  };
}
