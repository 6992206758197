import { AnyFunction } from "../definitions";

export function parseBoolean(val: string): boolean {
  /*                                  */
  return val === "" || val === "true";
}

export function parseInt(val: string): number {
  return Number.parseInt(val, 10);
}

export function parseCallback(val: string): AnyFunction {
  /*                                   */
  return Function(`return ${val};`)();
}

export function parseUb64e(val: string): string | undefined {
  try {
    return window.atob(val);
  } catch (error) {
    return undefined;
  }
}

export function parseJson<T>(val: string): T | undefined {
  try {
    return JSON.parse(val);
  } catch (error) {
    return undefined;
  }
}
