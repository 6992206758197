import type {
  AnyChildElement,
  BuilderFunction,
  ElementBuilder,
  ElementSafeBuilder,
} from "./builder";

/**
 *
 */
/*                                                          */
export type AnyBuilderFunction<E> = (element: E, ...a: any[]) => void;

export function processBuilder<E extends AnyChildElement, B extends BuilderFunction<E>>(
  elementBuilder: ElementBuilder<E>,
  builder: B[]
): ElementBuilder<E> {
  builder.forEach((b) => b.call(elementBuilder, elementBuilder.element));
  return elementBuilder;
}

export function getElementBuilder<E extends AnyChildElement>(element: E): ElementBuilder<E> {
  const res = ((...builder) => processBuilder(res, builder)) as ElementBuilder<E>;
  res.element = element;
  return res;
}

export function getElementSafeBuilder<E extends Element | undefined>(
  element: E
): ElementSafeBuilder<E> {
  const res = ((...builder) => {
    if (!res.element) {
      return res;
    }
    return processBuilder(res as ElementBuilder<NonNullable<E>>, builder);
  }) as ElementSafeBuilder<E>;

  res.element = element;
  return res;
}
