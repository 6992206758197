import { PatternProvide } from "../../../common/src/pattern/provide";
import { Ref, watch } from "../../../common/src/react";
import { debounceDelayReset } from "../../../common/src/utils";
import { CarouselProvides } from "../events";
import { ChangeSource } from "../types";

export function useEvents(
  data: {
    activeSlide: Ref<number>;
    visibleSlideIndices: Set<number>;
    scrollingRight: Ref<boolean>;
    changeSource: Ref<ChangeSource | undefined>;
    eventsEnabled: Ref<boolean>;
  },
  emit: PatternProvide<CarouselProvides>
): void {
  /*                                                                        */
  const payload = () => ({
    currentSlides: Array.from(data.visibleSlideIndices.values()),
    /*                                     */
    /*                                                  */
    /*                                                      */
    source: data.changeSource.value || "touch",
    scrollingRight: data.scrollingRight.value,
    activeSlide: data.activeSlide.value,
  });

  const stageChanged = debounceDelayReset(() => {
    emit.stageChanged(payload());
    /*                                    */
    /*                                               */
    /*                            */
    data.changeSource.value = undefined;
  }, 1000);

  watch(() => {
    if (!data.eventsEnabled.value) {
      return;
    }

    emit.slideChanged(payload());
    stageChanged();
  }, [data.activeSlide]);
}
