import { defineAccept, defineProvide } from "../../common/src/pattern";
import { ChangeSource } from "./types";

export interface CarouselCommonPayload {
  /**
 *
 *
 *
 *
 */
  currentSlides: number[];
  /**
 *
 *
 */
  activeSlide: number;
}

export type CarouselInitPayload = CarouselCommonPayload;

export interface CarouselChangePayload extends CarouselCommonPayload {
  /**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
  source: ChangeSource;

  /**
 *
 */
  scrollingRight: boolean;
}

export interface CarouselMovePayload {
  /**
 *
 */
  index: number;
  /**
 *
 *
 */
  snap?: boolean;
}

/**
 *
 */
export type CarouselAccepts = {
  /**
 *
 */
  move: [data: CarouselMovePayload];
};

export const ACCEPT = defineAccept<CarouselAccepts>({
  move: "move",
});

/**
 *
 */
export type CarouselProvides = {
  /**
 *
 *
 */
  created: [data: CarouselInitPayload];

  /**
 *
 */
  mounted: [data: CarouselInitPayload];
  /**
 *
 *
 *
 *
 *
 */
  "stage.changed": [data: CarouselChangePayload];

  /**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
  "slide.changed": [data: CarouselChangePayload];
};

export const PROVIDE = defineProvide<CarouselProvides>({
  created: "created",
  mounted: "mounted",
  stageChanged: "stage.changed",
  slideChanged: "slide.changed",
});
