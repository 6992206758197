import { AnyProps } from "../component/props";
import { AnyArgs, EmptyEvents, EmptyRecord, UndefinedToPartial } from "../definitions";
import { PatternConstructor } from "./construct";
import { PatternDomContext } from "./context";
import { AnyPatternDefinition } from "./definition";
import { eventName, eventQBus } from "./head";
import { MethodDefs } from "./methods";
import { AnyPattern, PatternType } from "./pattern";

/**
 *
 *
 */
export type PatternOptionsInvoke<Props> = UndefinedToPartial<Props>;

/**
 *
 *
 *
 */
export type BuildEventPayload<Payload, Props extends EmptyRecord, Pattern extends AnyPattern> = {
  /**
 *
 *
 *
 */
  element?: HTMLElement;
  /**
 *
 *
 */
  props?: Payload extends AnyArgs
    ? Payload[0] extends { props: infer U }
      ? PatternOptionsInvoke<U> & Omit<Props, keyof U>
      : PatternOptionsInvoke<Props>
    : never;
  /**
 *
 *
 */
  callback?: (pattern: Pattern) => void;
};

/**
 *
 *
 *
 */
export function registerGlobalBuild<
  Name extends string,
  Root extends HTMLElement,
  Props extends AnyProps,
  Data extends EmptyRecord,
  Accept extends EmptyEvents,
  Provide extends EmptyRecord,
  Methods extends MethodDefs<PatternDomContext<string, Root, Props, Data, Provide>>,
>(
  definition: AnyPatternDefinition,
  constructor: PatternConstructor<Name, Root, Props, Accept, Provide, Methods>,
): void {
  const { name } = definition;

  const initEvent = eventName(name, "build");

  eventQBus.on(
    initEvent,
    ({
      callback,
      element,
      props,
    }: BuildEventPayload<
      { props?: Props },
      Props,
      PatternType<PatternConstructor<Name, Root, Props, Accept, Provide, Methods>>
    >) => {
      /*                                                                      */
      callback?.(constructor(props || ({} as never), element as Root).element);
    },
  );
}
