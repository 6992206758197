import { domAppend } from "./append";
import { domAttrs } from "./attrs";
import { ElementBuilder, ToElement } from "./builder";
import { getElementBuilder } from "./builder.internal";
import { domAddClasses } from "./classes";

const namespaceURI = "http:/*                   */

/**
 *
 *
 *
 *
 */
export function domSvg<Type extends keyof SVGElementTagNameMap>(
  tagName: Type extends Element ? keyof HTMLElementTagNameMap : Type
): ElementBuilder<ToElement<Type>> {
  return getElementBuilder(document.createElementNS(namespaceURI, tagName) as ToElement<Type>);
}

const iconClasses = domAddClasses<SVGSVGElement>("pl_icon");
const iconAttrs = domAttrs<SVGSVGElement>({ role: "img" });

/**
 *
 *
 *
 *
 *
 */
export function domPatternIcon(icon: string, ns = "pl"): ElementBuilder<SVGSVGElement> {
  const href = `/assets-static/icons/${ns}_icon_${icon}.svg#${ns}_icon_${icon}`;
  return domSvg("svg")(
    iconClasses,
    iconAttrs,
    domAppend(
      domSvg("use")((e) => {
        e.setAttribute("href", href);
        e.setAttributeNS("http:/*                                          */
      })
    )
  );
}
