import { defineComponent, defineEmits } from "../../../common/src/component";
import {
  domAddClasses,
  domAddListener,
  domAppend,
  domAttrs,
  domClass,
  domCreate,
  domCreateMany,
  domIf,
  domProps,
  domStyle,
  domSuppresEvent,
  ElementBuilder,
} from "../../../common/src/dom";
import { computed, eagerCompute } from "../../../common/src/react";
import { parseBoolean } from "../../../common/src/utils";
import { classes } from "../classes";
import { IndexedHTMLElement } from "../types";

const gap = 3;
const width = 12;

/*                          */
const edgeSkip = 4;
/**
 *
 *
 *
 *
 *
 */
export function getIsBigElement(i: number): (to: [active: number, scrollable: number]) => boolean {
  return ([active, scrollable]) => {
    const leftEdge = edgeSkip;
    const rightEdge = scrollable - edgeSkip;

    return (
      /*            */
      (active > rightEdge && i > rightEdge - gap) ||
      /*           */
      (active < leftEdge && i < leftEdge + gap - 1) ||
      /*                   */
      (i > active - gap && i < active + gap)
    );
  };
}

export function computeTranslate(
  active: number,
  scrollable: number,
  scrollingRight: boolean,
  items: ElementBuilder<IndexedHTMLElement>[]
): string | undefined {
  if (scrollable < 7) {
    return undefined;
  }

  const leftEdge = edgeSkip;
  const rightEdge = scrollable - edgeSkip;

  let translate;
  /*                           */
  if (scrollingRight) {
    if (active > rightEdge) {
      translate = items[scrollable - 7].element.offsetLeft;
    } else if (active > 2) {
      translate = items[active].element.offsetLeft - gap * width;
    }
  } else if (active > gap && active < rightEdge) {
    translate = items[active].element.offsetLeft - gap * width;
  } else if (active < leftEdge) {
    translate = 0;
  }

  return typeof translate !== "undefined" ? `translateX(-${translate}px)` : undefined;
}

/**
 *
 */
export const Indicator = defineComponent({
  name: "indicator",

  props: {
    /*                                      */
    activeSlide: { type: parseInt, required: true },

    /*                                      */
    totalSlides: { type: parseInt, required: true },

    /**
 *
 *
 */
    scrollableSlides: { type: parseInt, required: true },

    /**
 *
 *
 *
 *
 *
 */
    scrollingRight: { type: parseBoolean, required: true },

    /**
 *
 *
 *
 */
    stageCanScroll: { type: parseBoolean, required: true },
  },

  emits: defineEmits<{
    /**
 *
 *
 */
    nextSlide: { to: number; sourceType: "pageIndicator" };
  }>("nextSlide"),

  setup({ emit }) {
    const onClick = (ev: PointerEvent): void => {
      ev.preventDefault();

      /*                                                                  */
      /*                                                                      */
      const target = (ev.target as HTMLElement).closest<IndexedHTMLElement>(
        `.${classes.pageIndicatorItem}`
      );

      if (target) {
        emit.nextSlide({ to: target.elementIndex, sourceType: "pageIndicator" });
      }
    };

    return { onClick };
  },

  dom({ data, props, root }) {
    const { activeSlide, totalSlides, scrollableSlides } = props;

    /*                     */
    const items = domCreateMany<IndexedHTMLElement>(
      props.totalSlides.value,
      "li",
      classes.pageIndicatorItem
    ).map((item, i) => {
      const title = computed(() => `go to ${i + 1}/${totalSlides.value}`);

      return item(
        /*                             */
        domClass({
          /*                                */
          //
          [classes.pageIndicatorItemActive]: computed(() => activeSlide.value === i),
          [classes.pageIndicatorItemBig]: eagerCompute(getIsBigElement(i), [
            activeSlide,
            scrollableSlides,
          ]),
        }),

        /*                               */
        domProps({ elementIndex: i, title }),

        /*                             */
        domAppend(domCreate("button")(domAttrs({ type: "button" }), domProps({ title })))
      );
    });

    const main = domCreate("ul", classes.pageIndicator)(
      /*                           */
      domSuppresEvent("touchstart"),

      /*                               */
      domAddListener("click", data.onClick),

      /*                                                            */
      domStyle({
        transform: eagerCompute(
          () =>
            computeTranslate(
              activeSlide.value,
              scrollableSlides.value,
              props.scrollingRight.value,
              items
            ),
          [activeSlide, props.stageCanScroll]
        ),
      })
    );

    /*                                                          */
    /*                                   */
    items.forEach((item, i) => {
      main(
        domIf(
          computed(() => scrollableSlides.value > i),
          item
        )
      );
    });

    root(
      domAddClasses(classes.pageIndicatorWrapper),
      domAppend(domCreate("div", classes.pageIndicatorFlex)(domAppend(main)))
    );
  },
});
