import { AnyFunction } from "../definitions";

/**
 *
 *
 *
 *
 *
 *
 *
 */
export function debounceDelayReset<CB extends AnyFunction>(
  callback: CB,
  timeout = 0
): (...args: Parameters<CB>) => void {
  let accumulator = 0;

  return (...args) => {
    /*                */
    accumulator += 1;

    setTimeout(() => {
      /*                     */
      accumulator -= 1;

      /*                    */
      if (accumulator < 1) {
        callback(...args);
      }
    }, timeout);
  };
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function debounceTrailingFirst<CB extends AnyFunction>(
  callback: CB,
  timeout = 0
): (...args: Parameters<CB>) => void {
  let scheduled = false;

  return (...args) => {
    if (!scheduled) {
      setTimeout(() => {
        callback(...args);
        scheduled = false;
      }, timeout);
      scheduled = true;
    }
  };
}

/**
 *
 *
 *
 *
 *
 *
 *
 */
export function debouncedTrailing<CB extends AnyFunction>(
  callback: CB,
  timeout = 0,
  ...args: Parameters<CB>
): () => void {
  const func = debounceTrailingFirst(callback, timeout);
  return () => func(...args);
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function waitForRepaint<CB extends AnyFunction>(
  callback: CB,
  count = 2,
  ...args: Parameters<CB>
): void {
  let done = 0;
  const awaiter = (): void => {
    if (count > done) {
      done += 1;
      window.requestAnimationFrame(awaiter);
      return;
    }

    callback(...args);
  };

  awaiter();
}
