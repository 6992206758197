import { computed, ref, signalEvent, watch } from "../../common/src/react";
import { ACCEPT, CarouselMovePayload, PROVIDE } from "./events";
import { domAddClasses, domIf, domQuery, ElementBuilder } from "../../common/src/dom";
import { patternName } from "./pattern";
import { debounceTrailingFirst, parseBoolean } from "../../common/src/utils";
import { definePattern, PatternEvents, PatternType } from "../../common/src/pattern";
import { domOn, PropType } from "../../common/src/component";
import { useEventHandlers } from "./use/eventHandlers";
import { Indicator } from "./components/indicator";
import { mapSlides } from "./dom/slides";
import { mapRoot } from "./dom/root";
import { classes } from "./classes";
import { domObserverThreshold } from "./dom/observerThreshold";
import { domStageObserver } from "./dom/stageObserver";
import { domMapComponent } from "../../common/src/component/utils";
import { Arrow } from "./components/arrow";
import { domAmountOfVisibleSlides } from "./dom/visibleSlides";
import { domStageScroll } from "./dom/stageScroll";
import { ChangeSource } from "./types";
import { useEvents } from "./use/events";

/**
 *
 */
export const stageEdgeWidthSize = 16;

export const Carousel = definePattern({
  name: patternName,

  props: {
    /**
 *
 *
 *
 *
 */
    noEdges: { type: parseBoolean, default: false },

    /**
 *
 */
    noPageIndicator: { type: parseBoolean, default: false },

    /**
 *
 *
 */
    noArrows: { type: parseBoolean, default: false },

    /**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
    mode: { type: String as PropType<"carousel" | "cinema">, default: "carousel" },
    /**
 *
 *
 *
 *
 *
 */
    threshold: { type: parseFloat },
  },

  provide: PROVIDE,
  accept: ACCEPT,

  setup({ props, emit }) {
    const intersectionBasis = computed(
      /*                                                                              */
      () => props.threshold.value ?? (props.mode.value === "carousel" ? 0.5 : 0.99),
    );
    const activeSlide = ref(0);
    const totalSlides = ref(0);
    const visibleSlidesCount = ref(1);
    const scrollableSlides = computed(() => totalSlides.value - visibleSlidesCount.value + 1);
    const stageCanScroll = ref(false);

    const data = {
      /*                                                                             */
      eventsEnabled: ref(false),
      /**
 *
 *
 */
      stageCanScroll,

      /**
 *
 *
 */
      scrollingRight: ref(true),
      /**
 *
 */
      scrollPageSize: computed(() =>
        props.mode.value === "carousel" ? 1 : visibleSlidesCount.value,
      ),
      /**
 *
 *
 *
 *
 *
 */
      stageEdgeWidth: computed(() =>
        props.mode.value === "cinema" && stageCanScroll.value && !props.noEdges.value
          ? stageEdgeWidthSize
          : 0,
      ),

      /*                                                            */
      changeSource: ref<ChangeSource>(),
      /*                                                                   */
      resizeEntry: ref<ResizeObserverEntry>(),

      /*                                                                 */
      thresholdBasis: intersectionBasis,
      /*                                                                     */
      intersectionThreshold: ref<number>(intersectionBasis),

      /*                                       */
      refreshTrigger: signalEvent<[]>(),
      /*                                          */
      collectTrigger: signalEvent<[]>(),

      /*                                                  */
      totalSlides,
      /*                                                                     */
      visibleSlidesCount,
      /*                                                                                        */
      visibleSlideIndices: new Set<number>(),
      /**
 *
 *
 *
 */
      scrollableSlides,
      /**
 *
 *
 *
 */
      activeSlide,
      /**
 *
 *
 */
      nextSlide: ref(0),
      /**
 *
 */
      airbagLeft: computed(() => activeSlide.value === 0),
      /**
 *
 */
      airbagRight: computed(() => activeSlide.value === scrollableSlides.value - 1),

      moveSnap: ref(false),
    };

    watch(
      debounceTrailingFirst(() => {
        data.refreshTrigger.emit();
      }, 100),
      [data.resizeEntry, props.mode],
    );

    useEvents(data, emit);
    const eventHandlers = useEventHandlers(data);

    return { ...data, ...eventHandlers };
  },

  //
  /*  */
  //
  dom({ root: element, props, data }) {
    const root = mapRoot({ element, ...data });

    /*                                                           */
    const stage = domQuery(`.${classes.stage}`, element)(
      domObserverThreshold(data),
      domStageObserver(data),
      domAmountOfVisibleSlides(data),
      domStageScroll(data),
    );

    /*             */
    /*                                  */
    const { initialActiveSlide } = mapSlides({ ...data, stage: stage.element });

    /*                    */
    const arrowsEnabled = computed(() => !props.noArrows.value);
    root(
      domIf(arrowsEnabled, () =>
        domMapComponent(
          `.${classes.arrow}:not(.${classes.arrowNext})`,
          root,
          (e: ElementBuilder<HTMLButtonElement> | undefined) =>
            Arrow({ arrowType: "left", airbag: data.airbagLeft }, e),
        )(domOn("nextSlide", data.scrollLeft)),
      ),
    );
    root(
      domIf(arrowsEnabled, () =>
        domMapComponent(
          `.${classes.arrow}.${classes.arrowNext}`,
          root,
          (e: ElementBuilder<HTMLButtonElement> | undefined) =>
            Arrow({ arrowType: "right", airbag: data.airbagRight }, e),
        )(domOn("nextSlide", data.scrollRight)),
      ),
    );

    /*                           */
    /*                                                            */
    root(
      domIf(
        computed(() => !props.noPageIndicator.value),
        () => Indicator({ ...data })(domOn("nextSlide", data.scrollTo)),
      ),
    );

    /*                                                         */
    data.nextSlide.value = initialActiveSlide;

    return root;
  },

  created({ data, emit }) {
    emit.created({
      currentSlides: Array.from(data.visibleSlideIndices.values()),
      activeSlide: data.activeSlide.value,
    });
  },

  mount({ root, data, emit }) {
    /*                                      */

    data.collectTrigger.emit();
    data.eventsEnabled.value = true;
    emit.mounted({
      currentSlides: Array.from(data.visibleSlideIndices.values()),
      activeSlide: data.activeSlide.value,
    });

    root(domAddClasses(classes.mainReady));
  },

  methods: {
    /**
 *
 *
 *
 *
 */
    move({ data }, payload: CarouselMovePayload) {
      data.moveSnap.value = !!payload.snap;
      data.changeSource.value = "event";
      data.nextSlide.value = payload.index;
      data.moveSnap.value = false;
    },
  },
});

export interface CarouselPattern extends PatternType<typeof Carousel> {}

export type CarouselEvents = PatternEvents<CarouselPattern>;
