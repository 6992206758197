/*                                                         */
import { Ref } from "../react";

import { BuilderFunction } from "./builder";
import { applyOrSubscribe, getDataFromDataOrFunc, PropsRecord } from "./internal";

function bindStyleInternal<E extends HTMLElement>(element: E, styleProps: PropsRecord): E {
  Object.entries(styleProps).forEach(([key, refVal]) => {
    applyOrSubscribe(refVal, (to) => {
      if (to === null) {
        element.style.removeProperty(key);
      } else if (to !== undefined) {
        element.style.setProperty(key, to);
      }
    });
  });

  return element;
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function domStyle<E extends HTMLElement>(styleProps: PropsRecord): BuilderFunction<E> {
  return (element: E) => bindStyleInternal(element, styleProps);
}

function removeStylePropsInternal<E extends HTMLElement>(
  element: E,
  styleProps: (() => string[]) | string[]
): E {
  const propsData = getDataFromDataOrFunc(styleProps);

  propsData.forEach((key) => {
    element.style.removeProperty(key);
  });

  return element;
}

/**
 *
 *
 *
 */
export function domRemoveStyleProps<E extends HTMLElement>(
  styleProps: string[]
): BuilderFunction<E> {
  return (element: E) => removeStylePropsInternal(element, styleProps);
}
