/*                                                   */
import { getRefImpl, isRef, Ref } from "../react";
import type { AnyFunction } from "../definitions";
import {
  AnyChildElement,
  AnyElementBuilder,
  AnyParentElement,
  ElementBuilder,
  isElementBuilder,
} from "./builder";
import { getElementBuilder } from "./builder.internal";
import { sub } from "../react/privates";

export type ParamsWithoutElement<P extends any[]> = P extends [any, ...infer U] ? U : never;

export type DataOrDataFunc<Data, Args extends any[]> = ((...a: Args) => Data) | Data;

export type StringOrRef = Ref<string | null | undefined> | (string | null | undefined);

export type PropsRecord = Record<string, StringOrRef>;

export function getDataFromDataOrFunc<Data, Args extends any[]>(
  data: DataOrDataFunc<Data, Args>,
  ...args: Args
): Data {
  return typeof data !== "function" ? data : (data as AnyFunction)(...args);
}

export function setClassNameIfGiven<E extends Element>(
  element: E,
  className: string | undefined,
): E {
  if (className) {
    element.className = className;
  }

  return element;
}

/**
 *
 *
 *
 *
 */
export function getBuilderToElement<
  B extends AnyParentElement | AnyChildElement | AnyElementBuilder,
>(elementOrBuilder: B): B extends ElementBuilder<infer U> ? U : B {
  return isElementBuilder(elementOrBuilder)
    ? elementOrBuilder.element
    : (elementOrBuilder as never);
}

export function getElementToBuilder<E extends AnyChildElement>(
  elementOrBuilder: E | ElementBuilder<E>,
): ElementBuilder<E> {
  return isElementBuilder(elementOrBuilder)
    ? elementOrBuilder
    : getElementBuilder(elementOrBuilder);
}

/**
 *
 *
 *
 *
 *
 */
export function applyOrSubscribe<T>(refVal: Ref<T> | T, func: (to: T, from: T) => void): void {
  if (isRef(refVal)) {
    getRefImpl(refVal)[sub](refVal, func, { immediate: true });
    return;
  }

  func(refVal, refVal);
}
