import type { AnyProps } from "../component/props";
import type { EmptyEvents, EmptyRecord } from "../definitions";
import { registerPatternAccept } from "./accept";
import { getPatternConstructor, PatternConstructor } from "./construct";
import { PatternDomContext } from "./context";
import { PatternDefinition } from "./definition";
import { registerGlobalBuild } from "./globalBuild";
import { registerGlobalInit } from "./globalInit";
import { MethodDefs } from "./methods";

export { defineAccept } from "./accept";
export { defineProvide } from "./provide";
export { eventQBus } from "./head";

export type { PatternAcceptEventEnum } from "./accept";
export type { PatternProvideEventEnum } from "./provide";

export type { AnyPatternConstructor, PatternConstructor } from "./construct";
export type { AnyPatternDefinition, PatternDefinition } from "./definition";
export type { PatternEvents } from "./events";
export type { AnyPattern, Pattern, PatternType } from "./pattern";
export type { MethodDefs as AnyMethods, PatternMethods } from "./methods";

export function definePattern<
  Name extends string,
  Root extends HTMLElement,
  Props extends AnyProps,
  Data extends EmptyRecord,
  Accept extends EmptyEvents,
  Provide extends EmptyRecord,
  Methods extends MethodDefs<PatternDomContext<Name, Root, Props, Data, Provide>>,
>(
  patternDef: PatternDefinition<Name, Root, Props, Data, Accept, Provide, Methods>,
): PatternConstructor<Name, Root, Props, Accept, Provide, Methods> {
  /*                          */
  const constructor = getPatternConstructor(patternDef);

  /*                             */
  registerGlobalInit(patternDef, constructor);

  /*                    */
  registerPatternAccept(patternDef);

  /*                   */
  registerGlobalBuild(patternDef, constructor);

  return constructor;
}
