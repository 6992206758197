import { MapSources } from "./internal";
import { AnyRef, ref, Ref } from "./ref";
import { watch, WatchOptions } from "./watch";
import { watchEffect } from "./watchEffect";

type WatchedCompute<T, R> = (to: MapSources<T>, from: MapSources<T>) => R;

/**
 *
 *
 *
 *
 *
 */
export function eagerCompute<T extends AnyRef[], R>(
  watcher: WatchedCompute<T, R>,
  watched: [...T],
  options: WatchOptions = {}
): Ref<R> {
  const result = ref() as Ref<R>;
  options.immediate ||= true;

  watch(
    (...args) => {
      result.value = watcher(...args);
    },
    watched,
    options
  );

  return result;
}

/**
 *
 *
 *
 *
 *
 */
export function eagerComputeEffect<T>(watcher: () => T): Ref<T> {
  const result = ref() as Ref<T>;

  watchEffect(() => {
    result.value = watcher();
  });

  return result;
}
