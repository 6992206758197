import { AnyConstructor, AnyFunction, UndefinedToPartial } from "../definitions";
import { AnyRef, computed, isRef, ref, Ref } from "../react";

/**
 *
 */
export type PropType<T> = (val: string) => T;

export type AnyProps = Record<string, PropDef<unknown> | AnyFunction>;

export type PropDef<T> = {
  type: AnyConstructor<T>;
  default?: unknown;
  required?: true;
};

export type DecomposeProp<T> = T extends PropDef<infer U>
  ? T extends { required: true }
    ? NonNullable<U>
    : T extends { default: T }
    ? NonNullable<U>
    : U
  : T extends AnyConstructor<infer U>
  ? U | undefined
  : never;

export type AnyComponentProps = Record<string, AnyRef>;

export type Properties<Props> = { [P in keyof Props]: Ref<DecomposeProp<Props[P]>> };

export type DecomposeInputProp<T> = T extends PropDef<infer U>
  ? T extends { required: true }
    ? NonNullable<U>
    : U | undefined
  : T extends AnyConstructor<infer U>
  ? U | undefined
  : never;

export type InputProps<Props> = UndefinedToPartial<{
  [P in keyof Props]:
    | Ref<DecomposeInputProp<Props[P]>>
    | DecomposeInputProp<Props[P]>
    | { value: Ref<DecomposeInputProp<Props[P]>>; sync: boolean };
}>;

export function createComponentProps<Props extends AnyProps>(
  propsDef: Props,
  inputProps: InputProps<Record<string, AnyRef | unknown>>
): Properties<Props> {
  const props = Object.fromEntries(
    Object.entries(propsDef).map(([key, defVal]) => {
      const inputProp: Ref<never> | { value: Ref<never>; sync: boolean } | never | undefined =
        inputProps[key as keyof InputProps<Record<string, AnyRef | unknown>>];

      let val: AnyRef;

      if (!inputProp && typeof defVal === "object" && "default" in defVal) {
        val = ref(defVal.default);
      } else if (isRef(inputProp)) {
        val = computed(() => inputProp.value);
      } else if (typeof inputProp === "object" && "sync" in inputProp && inputProp.sync) {
        val = inputProp.value;
      } else {
        val = ref(inputProp);
      }

      return [key, val];
    })
  );

  return props as Properties<Props>;
}

export function parseAttributeProp<T>(
  defVal: PropDef<unknown> | AnyFunction,
  value: unknown | undefined | null
): T | undefined {
  if (value === undefined || value === null) {
    return undefined;
  }

  const parser = typeof defVal === "function" ? defVal : defVal.type;
  return parser(value);
}

/**
 *
 *
 *
 *
 *
 */
export function defineProps<Props extends AnyProps>(props: Props): Props {
  return props;
}
