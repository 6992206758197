import { BuilderFunction } from "../../../common/src/dom";
import { Event, Ref, watch } from "../../../common/src/react";
import { getChildren } from "../../../common/src/utils";

export function computeScrollForMultipleItems(
  stage: HTMLElement,
  slides: HTMLElement[],
  nextSlide: number,
  visibleSlides: number
): number {
  const firstSlide = slides[nextSlide - 1];
  const lastSlide = slides[nextSlide + visibleSlides];

  /*             */
  const st = stage.getBoundingClientRect();
  /*                     */
  const fs = firstSlide.getBoundingClientRect();
  /*                    */
  const ls = lastSlide.getBoundingClientRect();

  /*                                                          */
  const slidesWidth = ls.right - fs.left;

  /*                                                         */
  /*                                   */
  return firstSlide.offsetLeft + (slidesWidth - st.width) / 2;
}

export function computeScrollForSingleItem(
  slides: HTMLElement[],
  nextSlide: number,
  stageEdgeWidth: number
): number {
  const nextElement = slides[nextSlide];
  return nextElement.offsetLeft - stageEdgeWidth;
}

export function processNextSlide(
  stage: HTMLElement,
  slides: HTMLElement[],
  visibleSlides: number,
  scrollableSlides: number,
  scrollPageSize: number,
  nextSlide: number,
  stageEdgeWidth: number
): void {
  if (scrollPageSize > 1 && nextSlide > 0 && nextSlide < scrollableSlides - 1) {
    stage.scrollLeft = computeScrollForMultipleItems(stage, slides, nextSlide, visibleSlides);
    return;
  }

  stage.scrollLeft = computeScrollForSingleItem(slides, nextSlide, stageEdgeWidth);
}

export function domStageScroll(data: {
  /**
 *
 */
  refreshTrigger: Event<[]>;
  stageCanScroll: Ref<boolean>;
  nextSlide: Ref<number>;
  visibleSlidesCount: Ref<number>;
  scrollableSlides: Ref<number>;
  stageEdgeWidth: Ref<number>;
  scrollPageSize: Ref<number>;
}): BuilderFunction<HTMLElement> {
  const { nextSlide } = data;
  return (stage) => {
    const slides = getChildren<HTMLElement>(stage);

    /*                                   */
    watch(() => {
      if (!slides[nextSlide.value]) {
        return;
      }

      processNextSlide(
        stage,
        slides,
        data.visibleSlidesCount.value,
        data.scrollableSlides.value,
        data.scrollPageSize.value,
        nextSlide.value,
        data.stageEdgeWidth.value
      );
    }, [nextSlide]);

    data.refreshTrigger.sub(() => {
      /*                                                           */
      data.stageCanScroll.value = stage.clientWidth < stage.scrollWidth;
    });
  };
}
