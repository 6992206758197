import type { CamelCase, PascalCase } from "type-fest";
import { camelcasify, pascalcasify } from "../utils/string";

/*                                                          */
/*                                                          */
type AnyBemElementBuilder = BemElementBuilder;

/*                                                                   */
type ComposeKey<Block extends string, Element extends string> = Block extends ""
  ? Element
  : `${CamelCase<Block>}${PascalCase<Element>}`;

/*                                                                   */
function composeKey<Block extends string, Element extends string>(
  block: Block,
  element: Element,
): ComposeKey<Block, Element> {
  return camelcasify(block).concat(pascalcasify(element)) as never;
}

/*                                                                           */
type ComposeElement<Block extends string, Element extends string> = Block extends ""
  ? Element
  : `${Block}__${Element}`;

/*                                                                           */
function composeElement<Block extends string, Element extends string>(
  block: Block,
  element: Element,
): ComposeElement<Block, Element> {
  return block.concat("__", element) as never;
}

/*                                                                            */
type ComposeModifier<
  Block extends string,
  Element extends string,
  Modifier extends string,
> = Block extends "" ? `${Element}--${Modifier}` : `${Block}__${Element}--${Modifier}`;

/*                                                                            */
function composeModifier<Block extends string, Element extends string, Modifier extends string>(
  block: Block,
  element: Element,
  modifier: Modifier,
): ComposeModifier<Block, Element, Modifier> {
  return (block ? block.concat("__") : "").concat(element, "--", modifier) as never;
}

/*                                         */
export type BemUnwrap<Block extends string, Modifiers extends string[]> = { main: Block } & {
  [P in Modifiers[number] as ComposeKey<"main", P>]: ComposeModifier<"", Block, P>;
} & { [key: string]: string };

/*                                                                                */
function bemUnwrap<
  Prefix extends string,
  Name extends string,
  Modifiers extends string[],
  Elements extends AnyBemElementBuilder[],
>(prefix: Prefix, name: Name, modifiers: Modifiers, elements: Elements): never {
  /**
 *
 */
  const res = [["main", name]] as [string, string][];
  modifiers.forEach((m) => res.push([composeKey("main", m), composeModifier("", name, m)]));
  elements.forEach((e) => res.push(...e(name)));

  const prefixed = prefix ? res.map((r) => [r[0], prefix.concat("_", r[1])]) : res;
  const jsed = prefixed.map((r) => [`${r[0]}Js`, `js_${r[1]}`]);

  return Object.fromEntries([...prefixed, ...jsed]) as never;
}

/*                                                */
export type BemBlockBuilder = {
  /**
 *
 *
 */
  <Elements extends AnyBemElementBuilder[]>(...elements: Elements): { [key: string]: string };
};

/*                                                                                                   */
function createBemBlockBuilder<
  Prefix extends string,
  Name extends string,
  Modifiers extends string[],
>(prefix: Prefix, name: Name, ...modifiers: Modifiers): BemBlockBuilder {
  return (...elements) => bemUnwrap(prefix, name, modifiers, elements);
}

/*                                                       */
export type BemBlockFunction = {
  /**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
  <Name extends string, Modifiers extends string[]>(
    name: Name,
    ...modifiers: Modifiers
  ): BemBlockBuilder;
};

/*                                      */
export type BemBlockDefault = {
  /**
 *
 *
 *
 */
  prefix(prefix: string): BemBlockFunction;
};

function createBemBlockFunc(prefix: string): BemBlockFunction {
  return (name, ...modifiers) => createBemBlockBuilder(prefix, name, ...modifiers);
}

/*                                        */
export const bemBlock = createBemBlockFunc("pl") as BemBlockDefault & BemBlockFunction;
bemBlock.prefix = createBemBlockFunc;

export type BemElementBuilder = {
  <Block extends string>(block: Block): [string, string][];
};

function buildBemElement<Block extends string, Name extends string, Modifiers extends string[]>(
  block: Block,
  name: Name,
  modifiers: Modifiers,
): [
  CamelCase<Name> | ComposeKey<Name, Modifiers[number]>,
  ComposeElement<Block, Name> | ComposeModifier<Block, Name, Modifiers[number]>,
][] {
  const res: [string, string][] = [[camelcasify(name), composeElement(block, name)]];
  modifiers.forEach((m) => res.push([composeKey(name, m), composeModifier(block, name, m)]));
  return res as never;
}

/**
 *
 *
 *
 *
 */
export function bemElement<Name extends string, Modifiers extends string[]>(
  name: Name,
  ...modifiers: Modifiers
): BemElementBuilder {
  return (block) => buildBemElement(block, name, modifiers);
}
