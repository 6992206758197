import { AnyProps, createComponentProps, InputProps } from "../component/props";
import type { EmptyEvents, EmptyRecord } from "../definitions";
import { domCreate, ElementBuilder } from "../dom";
import { getElementBuilder } from "../dom/builder.internal";
import { getElementToBuilder } from "../dom/internal";
import { defineProperty, waitForRepaint } from "../utils";
import type { PatternDomContext } from "./context";
import type { PatternDefinition } from "./definition";
import { AnyMethodDefs, getMethods, PatternMethods } from "./methods";
import { readDataOptions } from "./options";
import { isPattern, Pattern } from "./pattern";
import { createProvideWrapper, PatternProvideEventEnum } from "./provide";
import { elementToPattern } from "./registry";

export type AnyPatternConstructor = PatternConstructor<
  string,
  HTMLElement,
  AnyProps,
  EmptyEvents,
  EmptyEvents,
  EmptyRecord
>;

export type PatternConstructor<
  Name extends string,
  Root extends HTMLElement,
  Props extends AnyProps,
  Accept extends EmptyRecord,
  Provide extends EmptyRecord,
  Methods extends AnyMethodDefs
> = {
  (
    props: { [P in keyof InputProps<Props>]: InputProps<Props>[P] },
    root?: Root | ElementBuilder<Root>
  ): ElementBuilder<Pattern<Name, Root, Props, Accept, Provide, PatternMethods<Methods>>>;
};

export function constructPattern<
  Name extends string,
  Root extends HTMLElement,
  Props extends AnyProps,
  Data extends EmptyRecord,
  Accept extends EmptyRecord,
  Provide extends EmptyRecord,
  Methods extends AnyMethodDefs
>(
  definition: PatternDefinition<Name, Root, Props, Data, Accept, Provide, Methods>,
  inputProps: InputProps<Props>,
  existingRoot?: Root | ElementBuilder<Root>
): ElementBuilder<Pattern<Name, Root, Props, Accept, Provide, PatternMethods<Methods>>> {
  type Context = PatternDomContext<Name, Root, Props, Data, Provide>;

  const {
    name,
    dom,
    root: rootDef = { tag: "div", type: HTMLDivElement },
    props: propsDef = {} as Props,
    provide: provideDef = {} as PatternProvideEventEnum<Provide>,
    setup,
    mount,
    unmount,
    created,
    methods = {} as Methods,
  } = definition;

  const componentContext = { name } as Context;

  /*                                */
  const rootBuilder = (
    existingRoot ? getElementToBuilder(existingRoot) : domCreate(rootDef.tag)
  ) as ElementBuilder<Pattern<Name, Root, Props, Accept, Provide, PatternMethods<Methods>>>;

  const rootElement = rootBuilder.element;

  /*                                                                       */
  elementToPattern.set(rootElement, componentContext);

  /*                  */
  componentContext.props = createComponentProps(propsDef, inputProps);

  /*                                       */
  readDataOptions(rootElement, definition, componentContext.props);

  /*                                                       */
  /*                              */
  componentContext.emit = createProvideWrapper(rootElement, provideDef);

  /*                         */
  componentContext.data = setup?.(componentContext) ?? ({} as Data);

  /*                                       */
  dom?.({ ...componentContext, root: rootBuilder });

  /*                                                     */
  /*                     */
  rootElement.onMount = function onMount() {
    const context = elementToPattern.get(this) as Context;
    const builder = getElementBuilder(this);
    mount && waitForRepaint(mount, 3, { ...context, root: builder });
  };

  rootElement.onUnmount = function onUnmount() {
    const context = elementToPattern.get(this) as Context;
    const builder = getElementBuilder(this);
    unmount?.({ ...context, root: builder });
  };

  /*                                                                            */
  defineProperty(rootElement, "patternName", { writable: false, value: name });

  /*                                                     */
  defineProperty(rootElement, "props", {
    get() {
      return elementToPattern.get(this)?.props;
    },
  });

  /*                               */
  defineProperty(rootElement, "methods", {
    get() {
      return getMethods(this, methods);
    },
  });

  /*                                          */
  created?.({ ...componentContext, root: rootBuilder });

  /*                                  */
  if (rootElement.parentElement) {
    rootElement.onMount(rootElement.parentElement);
  }

  return rootBuilder;
}

export function getPatternConstructor<
  Name extends string,
  Root extends HTMLElement,
  Props extends AnyProps,
  Data extends EmptyRecord,
  Accept extends EmptyEvents,
  Provide extends EmptyRecord,
  Methods extends AnyMethodDefs
>(
  patternDef: PatternDefinition<Name, Root, Props, Data, Accept, Provide, Methods>
): PatternConstructor<Name, Root, Props, Accept, Provide, Methods> {
  return (props, root) => {
    if (isPattern(root, patternDef.name)) {
      /*                                                      */
      return elementToPattern.get(root) as never;
    }

    /*                            */
    return constructPattern(patternDef, props as InputProps<Props>, root);
  };
}
