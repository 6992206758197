import { arrayify } from "../utils";
import { AnyChild, notifiyMount, notifiyUnmount } from "./append.internal";
import { AnyChildElement, AnyParentElement, BuilderFunction, ElementBuilder } from "./builder";
import { getBuilderToElement } from "./internal";

function process(
  anyChildren: (AnyChild | AnyChild[])[],
  proc: (child: AnyChild<Node>) => void
): void {
  anyChildren.forEach((childOrBuilder) => {
    arrayify(childOrBuilder).forEach(proc);
  });
}

/**
 *
 *
 *
 *
 *
 */
export function domAppend<E extends AnyParentElement, C extends (AnyChild | AnyChild[])[]>(
  ...children: C
): BuilderFunction<E> {
  return (element) =>
    process(children, (c) => {
      const child = getBuilderToElement(c);
      element.appendChild(child);
      notifiyMount(element, child);
    });
}

/**
 *
 *
 *
 *
 *
 */
export function domRemove<E extends Element, C extends (AnyChild | AnyChild[])[]>(
  ...children: C
): BuilderFunction<E> {
  return (element) =>
    process(children, (c) => {
      const child = getBuilderToElement(c);
      element.removeChild(child);
      notifiyUnmount(element, child);
    });
}

/**
 *
 *
 *
 *
 *
 *
 */
export function domReplace<
  E extends AnyParentElement,
  C extends AnyChildElement,
  P extends AnyChildElement
>(current: C | ElementBuilder<C>, previous: P | ElementBuilder<P>): BuilderFunction<E> {
  return (element) => {
    const c = getBuilderToElement(current);
    const p = getBuilderToElement(previous);
    element.replaceChild(c, p);
    notifiyMount(element, c);
    notifiyUnmount(element, p);
  };
}

export function domIncertBefore<E extends Element, C extends Element, P extends Element>(
  current: C | ElementBuilder<C>,
  before: P | ElementBuilder<P>
): BuilderFunction<E> {
  return (element) => {
    const c = getBuilderToElement(current);
    element.insertBefore(c, getBuilderToElement(before));
    notifiyMount(element, c);
  };
}

export function domIncertAdjastent<E extends Element, C extends Element>(
  current: C | ElementBuilder<C>,
  where: InsertPosition
): BuilderFunction<E> {
  return (element) => {
    const c = getBuilderToElement(current);
    element.insertAdjacentElement(where, c);
    notifiyMount(element, c);
  };
}
