import { Ref } from "../../../common/src/react";
import { ChangeSource } from "../types";

/**
 *
 */
/*                                                                        */
export function useEventHandlers({
  activeSlide,
  nextSlide,
  changeSource,
  scrollableSlides,
  scrollPageSize,
}: {
  /**
 *
 */
  activeSlide: Ref<number>;

  /**
 *
 */
  scrollableSlides: Ref<number>;
  /**
 *
 */
  scrollPageSize: Ref<number>;
  /**
 *
 */
  nextSlide: Ref<number>;
  /**
 *
 */
  changeSource: Ref<ChangeSource | undefined>;
}) {
  const scrollLeft = (ev: { detail: { sourceType: ChangeSource } }): void => {
    const prev = activeSlide.value - scrollPageSize.value;

    /*                                    */
    nextSlide.value = prev < 0 ? 0 : prev;
    changeSource.value = ev.detail.sourceType;
  };

  const scrollRight = (ev: { detail: { sourceType: ChangeSource } }): void => {
    /*                                                                            */
    const next = activeSlide.value + scrollPageSize.value;

    /*                                    */
    nextSlide.value = next >= scrollableSlides.value ? scrollableSlides.value - 1 : next;
    changeSource.value = ev.detail.sourceType;
  };

  const scrollTo = (ev: { detail: { to: number; sourceType: ChangeSource } }): void => {
    nextSlide.value = ev.detail.to;
    changeSource.value = ev.detail.sourceType;
  };

  const keyUp = (ev: KeyboardEvent): void => {
    if (ev.key === "ArrowLeft") {
      scrollLeft({ detail: { sourceType: "KeyArrowLeft" } });
    } else if (ev.key === "ArrowRight") {
      scrollRight({ detail: { sourceType: "KeyArrowRight" } });
    }
  };

  return { scrollLeft, scrollRight, scrollTo, keyUp };
}
