import type { AnyChildElement, AnyParentElement, ElementBuilder, ElementSafeBuilder } from "../dom";
import { getBuilderToElement } from "../dom/internal";

/**
 *
 */
export type ElementToResult<E extends Element | null | undefined, R> = undefined extends E
  ? R | undefined
  : null extends E
  ? R | undefined
  : R;

/**
 *
 *
 *
 *
 *
 */
export function hasClass<E extends Element>(
  element: E | ElementBuilder<E>,
  token: string
): boolean {
  return getBuilderToElement(element).classList.contains(token);
}

/**
 *
 *
 *
 *
 *
 *
 *
 */
export function toggleClass<E extends Element | null | undefined>(
  element: E | ElementSafeBuilder<E> | ElementBuilder<NonNullable<E>>,
  token: string,
  force?: boolean
): ElementToResult<E, boolean> {
  const domelement = typeof element !== "function" ? element : element.element;
  return domelement?.classList.toggle(token, force) as never;
}

export function contains<E extends AnyParentElement, C extends AnyChildElement>(
  parent: E | ElementBuilder<E>,
  child: C | ElementBuilder<C>
): boolean {
  return getBuilderToElement(parent).contains(getBuilderToElement(child));
}

export function getChildren<C extends Element, E extends AnyParentElement = AnyParentElement>(
  parent: E | ElementBuilder<E>
): C[] {
  return Array.from(getBuilderToElement(parent).children) as C[];
}
