import { Ref } from "../react";
import { BuilderFunction } from "./builder";
import { applyOrSubscribe } from "./internal";

function addClassesInternal<E extends Element>(element: E, tokens: string[]): E {
  element.classList.add(...tokens);
  return element;
}

/**
 *
 *
 *
 *
 */
export function domAddClasses<T extends Element>(...tokens: string[]): BuilderFunction<T> {
  return (element) => addClassesInternal(element, tokens);
}

function removeClassesInternal<E extends Element>(element: E, tokens: string[]): E {
  element.classList.remove(...tokens);
  return element;
}

/**
 *
 *
 *
 *
 */
export function domRemoveClasses<T extends Element>(...tokens: string[]): BuilderFunction<T> {
  return (element) => removeClassesInternal(element, tokens);
}

type BooleanOrRef = Ref<boolean | null | undefined> | (boolean | null | undefined);
type ClassesRecord = Record<string, BooleanOrRef>;

function bindClassesInternal<E extends Element>(element: E, classMap: ClassesRecord): E {
  Object.entries(classMap).forEach(([className, refVal]) => {
    applyOrSubscribe(refVal, (to) => {
      if (to === null || to === false) {
        removeClassesInternal(element, [className]);
      } else if (to) {
        addClassesInternal(element, [className]);
      }
    });
  });

  return element;
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function domClass<E extends Element, P extends ClassesRecord>(
  classMap: P
): BuilderFunction<E> {
  return (element: E) => bindClassesInternal<E>(element, classMap);
}
