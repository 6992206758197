import type { AnyFunction } from "../definitions";
import { Ref } from "../react";
import type { BuilderFunction } from "./builder";
import { applyOrSubscribe } from "./internal";

/**
 *
 */
type PropsFilter<T extends Element> = {
  [P in keyof T]: T[P] extends AnyFunction /*               */
    ? never
    : { -readonly [Q in P]: T[P] } extends { [Q in P]: T[P] } /*              */
    ? P
    : never;
}[keyof T];

/**
 *
 */
type WriteableProps<E extends Element> = {
  [P in PropsFilter<E>]?: Ref<E[P] | null | undefined> | (E[P] | null | undefined);
};

function bindPropsInternal<E extends Element>(element: E, attrsProps: WriteableProps<E>): E {
  Object.entries(attrsProps).forEach(([key, refVal]) => {
    applyOrSubscribe(refVal, (to): void => {
      element[key as PropsFilter<E>] = to as never;
    });
  });

  return element;
}

/**
 *
 *
 *
 *
 *
 *
 */
export function domProps<E extends Element>(props: WriteableProps<E>): BuilderFunction<E> {
  return (element: E) => bindPropsInternal(element, props);
}
