import { BuilderFunction } from "./builder";
import { applyOrSubscribe, PropsRecord } from "./internal";

function bindAttrsInternal<E extends Element>(element: E, attrsProps: PropsRecord): E {
  Object.entries(attrsProps).forEach(([key, refVal]) => {
    applyOrSubscribe(refVal, (to) => {
      if (to === null) {
        element.removeAttribute(key);
      } else if (to !== undefined) {
        element.setAttribute(key, to);
      }
    });
  });

  return element;
}

/**
 *
 *
 *
 *
 */
export function domAttrs<E extends Element>(attributes: PropsRecord): BuilderFunction<E> {
  return (element) => bindAttrsInternal(element, attributes);
}
