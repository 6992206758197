import { BuilderFunction } from "../../../common/src/dom";
import { Event, Ref } from "../../../common/src/react";
import { getChildren } from "../../../common/src/utils";

function isPartiallyLeft(stage: DOMRect, slide: DOMRect): number {
  return stage.left >= slide.left && stage.left <= slide.right ? slide.right - stage.left : 0;
}

function isPartiallyRight(stage: DOMRect, slide: DOMRect): number {
  return stage.right <= slide.right && stage.right >= slide.left ? stage.right - slide.left : 0;
}

function isFullyVisible(stage: DOMRect, slide: DOMRect): boolean {
  return stage.left <= slide.left && stage.right >= slide.right;
}

function isIntersecting(slide: DOMRect, visibleWidth: number, threshold: number): boolean {
  return visibleWidth / slide.width >= threshold;
}

export function computeVisibleSlidesEx(
  data: {
    intersectionThreshold: Ref<number>;
  },
  stage: HTMLElement,
  slides: HTMLElement[]
): number[] {
  const visibleSlides: number[] = [];
  const st = stage.getBoundingClientRect();
  const th = data.intersectionThreshold.value;

  slides.every((s, i) => {
    const sl = s.getBoundingClientRect();

    const left = isPartiallyLeft(st, sl);
    const right = isPartiallyRight(st, sl);

    if (
      isFullyVisible(st, sl) ||
      (left && isIntersecting(sl, left, th)) ||
      (right && isIntersecting(sl, right, th))
    ) {
      visibleSlides.push(i);
      return true;
    }

    /*                                                              */
    /*                 */
    return !visibleSlides.length;
  });

  /*                                                         */
  return visibleSlides;
}

/**
 *
 *
 *
 */
export function domAmountOfVisibleSlides(input: {
  /**
 *
 *
 */
  visibleSlidesCount: Ref<number>;
  /**
 *
 *
 *
 *
 */
  visibleSlideIndices: Set<number>;
  /**
 *
 *
 */
  intersectionThreshold: Ref<number>;
  /**
 *
 */
  refreshTrigger: Event<[]>;

  collectTrigger: Event<[]>;
}): BuilderFunction<HTMLElement> {
  const { visibleSlideIndices, visibleSlidesCount } = input;

  return (stage) => {
    const slides = getChildren<HTMLElement>(stage);

    input.collectTrigger.sub(() => {
      const indices = computeVisibleSlidesEx(input, stage, slides);
      indices.forEach((i) => visibleSlideIndices.add(i));
      visibleSlidesCount.value = indices.length;
    });

    const refreshVisibleSlides = (): void => {
      const indices = computeVisibleSlidesEx(input, stage, slides);
      visibleSlidesCount.value = indices.length;
    };

    refreshVisibleSlides();
    input.refreshTrigger.sub(refreshVisibleSlides);
  };
}
