import { AnyRef } from "../react";
import { arrayify } from "../utils";
import { notifiyMount, notifiyUnmount } from "./append.internal";
import { AnyChildElement, AnyElementBuilder, AnyParentElement, BuilderFunction } from "./builder";
import { applyOrSubscribe, getBuilderToElement } from "./internal";

type AnyChildren = AnyChildElement | AnyElementBuilder | AnyChildElement[] | AnyElementBuilder[];

function getElementsFromAny<C extends AnyChildren | (() => AnyChildren)>(
  child: C
): AnyChildElement[] {
  /*                                                                   */
  const childOrArray = (
    typeof child === "function" && !("element" in child) ? child() : child
  ) as AnyChildren;

  /*                                   */
  const children = arrayify(childOrArray);

  return children.map(getBuilderToElement);
}

/**
 *
 *
 *
 *
 *
 */
export function domIf<E extends AnyParentElement, C extends AnyChildren>(
  ref: AnyRef,
  child: C | (() => C)
): BuilderFunction<E> {
  const placeholder = document.createComment("o-if");

  let getChildren = (): AnyChildElement[] => {
    const c = getElementsFromAny(child);
    getChildren = () => c;
    return getChildren();
  };

  let setVisible = (element: AnyParentElement): void => {
    let replacer: BuilderFunction<AnyParentElement> | undefined;

    setVisible = (e) => {
      if (e.contains(placeholder)) {
        getChildren().forEach((c) => {
          e.insertBefore(getBuilderToElement(c), placeholder);
          notifiyMount(e, c);
        });
        e.removeChild(placeholder);
      }
    };
    setVisible(element);
  };

  let setHidden = (element: AnyParentElement): void => {
    let replacer: BuilderFunction<AnyParentElement> | undefined;

    setHidden = (e) => {
      if (!e.contains(placeholder)) {
        const children = getChildren();
        const first = children[0];
        e.insertBefore(placeholder, getBuilderToElement(first));
        children.forEach((c) => {
          e.removeChild(getBuilderToElement(c));
          notifiyUnmount(e, c);
        });
      }
    };
    setHidden(element);
  };

  return (element) => {
    element.appendChild(placeholder);
    applyOrSubscribe(ref, (isVisible) => {
      (isVisible ? setVisible : setHidden)(element);
    });

    return element;
  };
}
