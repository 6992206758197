import type { AnyArgs, EmptyEvents, EventArgsToFunctionArgs } from "../definitions";
import { eventName, eventQBus } from "./head";
import type { MergeEventPayload, CamelcasifyEvent, StandardProvideEvents, Expand } from "./events";
import type { AnyPattern } from "./pattern";
import { dispatchEvent as dispatchOnDom } from "../component/events";

export type PatternProvideEventEnum<Events> = {
  [P in keyof (Events & StandardProvideEvents) as CamelcasifyEvent<P>]: P;
};

/**
 *
 */
export interface CommonPatternProvidePayload<Pattern extends AnyPattern> {
  /**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
  element: Pattern;
  /**
 *
 *
 *
 */
  id?: string;
}

export type UpdateProvideEvents<Pattern extends AnyPattern, Provide extends EmptyEvents> = {
  [P in keyof Provide]: MergeEventPayload<Provide[P], CommonPatternProvidePayload<Pattern>>;
};

export type PatternProvide<Events> = {
  [P in keyof (Events & StandardProvideEvents) as CamelcasifyEvent<P>]: (
    ...args: EventArgsToFunctionArgs<(Events & StandardProvideEvents)[P]>
  ) => void;
};

export type AsProvidePayload<Payload, Pattern extends AnyPattern> = Expand<
  CommonPatternProvidePayload<Pattern> & Payload
>;

export function defineProvide<Provide>(
  provide: PatternProvideEventEnum<Provide>,
): PatternProvideEventEnum<Provide> {
  return provide;
}

function dispatchEvent(event: string, instance: AnyPattern, args: AnyArgs): void {
  const topicName = eventName(instance.patternName, event);

  /*                                           */
  const payload: CommonPatternProvidePayload<AnyPattern> = {
    element: instance,
    id: instance.id,
    ...args[0],
  };

  /*                  */
  dispatchOnDom(instance, topicName, payload);

  /*                      */
  eventQBus.emit(topicName, payload, ...args.slice(1));
}

export function createProvideWrapper<Provide extends EmptyEvents>(
  element: AnyPattern,
  provide: PatternProvideEventEnum<Provide>,
): PatternProvide<Provide> {
  if (!provide) {
    return {} as never;
  }

  const res = Object.entries(provide).map(([e, v]) => [
    e,
    (...args: AnyArgs) => {
      dispatchEvent(v as string, element, args);
    },
  ]);

  return Object.freeze(Object.fromEntries(res)) as never;
}
