/*                                                   */
import type { AnyProps, Properties } from "../component/props";
import type { AnyFunction, EmptyEvents, EmptyRecord } from "../definitions";
import { ElementBuilder, HasMountHooks, isElementBuilder } from "../dom";
import type { PatternAccept } from "./accept";
import type { PatternConstructor } from "./construct";
import type { AnyPatternMethods } from "./methods";
import type { PatternProvide } from "./provide";

export type AnyPattern = Pattern<
  string,
  HTMLElement,
  EmptyRecord,
  EmptyEvents,
  EmptyEvents,
  Record<string, AnyFunction>
>;

export interface PatternImpl<
  Name extends string,
  Props extends AnyProps,
  Accept extends EmptyEvents,
  Provide extends EmptyEvents,
  Methods extends AnyPatternMethods
> extends HasMountHooks {
  patternName: Name;

  props: { [P in keyof Properties<Props>]: Properties<Props>[P] };

  emit: PatternProvide<Provide>;

  methods: Omit<Methods, keyof PatternAccept<Accept>> & PatternAccept<Accept>;

  accept: PatternAccept<Accept>;
}

export type Pattern<
  Name extends string,
  Root extends HTMLElement,
  Props extends AnyProps,
  Accept extends EmptyEvents,
  Provide extends EmptyEvents,
  Methods extends AnyPatternMethods
> = Root & PatternImpl<Name, Props, Accept, Provide, Methods>;

export type PatternType<Constructor extends PatternConstructor<string, any, any, any, any, any>> =
  ReturnType<Constructor>["element"];

export function isPattern(
  element: Element | ElementBuilder<Element> | undefined,
  name: string
): element is AnyPattern {
  if (!element) {
    return false;
  }

  const el = (isElementBuilder(element) ? element.element : element) as Partial<AnyPattern>;
  return "patternName" in el && !!el.patternName && el.patternName === name;
}
