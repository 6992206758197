export type AnyChildElement = Node | CharacterData;

export type AnyParentElement = Element | ParentNode;

/*                                                          */
export type AnyElementBuilder = ElementBuilder<any>;

/**
 *
 *
 *
 *
 */
export interface BuilderFunction<E extends AnyParentElement | AnyChildElement> {
  /**
 *
 */
  (element: E): void;
}

/**
 *
 *
 */
export type ToElement<
  Type extends keyof HTMLElementTagNameMap | keyof SVGElementTagNameMap | Element
> = Type extends Element
  ? Type
  : Type extends keyof HTMLElementTagNameMap
  ? HTMLElementTagNameMap[Type]
  : Type extends keyof SVGElementTagNameMap
  ? SVGElementTagNameMap[Type]
  : never;

export interface HasMountHooks {
  /**
 *
 *
 *
 */
  onMount(parent: AnyParentElement): void;
  /**
 *
 *
 */
  onUnmount(): void;
}

/**
 *
 *
 */
export interface ElementBuilder<E extends AnyParentElement | AnyChildElement> {
  (...builder: BuilderFunction<E>[]): ElementBuilder<E>;
  element: E;
}

/*                           */
export interface ElementSafeBuilder<
  E extends AnyParentElement | AnyChildElement | undefined | null
> {
  (...builder: BuilderFunction<NonNullable<E>>[]): ElementSafeBuilder<E>;
  element: E;
}

export function isElementBuilder<E extends AnyChildElement>(
  value: E | ElementBuilder<E>
): value is ElementBuilder<E> {
  return typeof value === "function" && "element" in value;
}
