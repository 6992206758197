import type { AnyArgs, EmptyEvents, EmptyRecord, EventArgsToFunctionArgs } from "../definitions";
import { eventName, eventQBus } from "./head";
import type { AnyPatternDefinition } from "./definition";
import type { MergeEventPayload, CamelcasifyEvent, StandardAcceptEvents } from "./events";
import type { BuildEventPayload } from "./globalBuild";
import type { AnyPattern } from "./pattern";

export type UpdateAcceptEvents<
  Pattern extends AnyPattern,
  Accept extends EmptyEvents,
  Options extends EmptyRecord
> = {
  [P in keyof Accept]: P extends "build"
    ? [data: BuildEventPayload<Accept[P], Options, Pattern>]
    : MergeEventPayload<Accept[P], CommonPatternAcceptPayload>;
};

/**
 *
 *
 *
 *
 */
export type PatternAcceptEventEnum<Events> = {
  [P in keyof (Events & StandardAcceptEvents) as CamelcasifyEvent<P & string>]: P;
};

export type CommonPatternAcceptPayload = Partial<{
  /**
 *
 *
 */
  element: HTMLElement;
  /**
 *
 *
 *
 */
  id: string;
  /**
 *
 *
 */
  selector: string;
}>;

export type PatternAccept<Accept extends EmptyEvents> = {
  [P in keyof (Accept & StandardAcceptEvents) as CamelcasifyEvent<P>]: (
    ...args: EventArgsToFunctionArgs<(Accept & StandardAcceptEvents)[P]>
  ) => void;
};

export function defineAccept<Accept>(
  accept: PatternAcceptEventEnum<Accept>
): PatternAcceptEventEnum<Accept> {
  return accept;
}

function callPatternAccept(methodName: string, args: AnyArgs): void {
  const [data = {}] = args as [data?: CommonPatternAcceptPayload];
  const { id, selector, element } = data;

  const elements = [];
  if (element) {
    elements.push(element);
  } else if (id) {
    const el = document.getElementById(id);
    if (el) {
      elements.push(el);
    }
  } else if (selector) {
    elements.push(...document.querySelectorAll(selector));
  }

  (elements as AnyPattern[]).forEach((el) => {
    if ("patternName" in el) {
      /*                                              */
      el.methods[methodName](...args);
    }
  });
}

export function registerPatternAccept(definition: AnyPatternDefinition): void {
  if (!definition.accept) {
    return undefined;
  }

  /*                */
  Object.entries(definition.accept).forEach(([key, event]) => {
    eventQBus.on(eventName(definition.name, event), (...args) => callPatternAccept(key, args));
  });

  return undefined;
}
