import {
  Derivative,
  fillWatches,
  getFromRegistry,
  MapSources,
  refRegistry,
  unrefWatch,
} from "./internal";
import { AnyRef, getRefImpl, Ref } from "./ref";
import * as privates from "./privates";

export type Watcher<T> = (to: MapSources<T>, from: MapSources<T>) => void;

export type WatchOptions = { once?: boolean; immediate?: boolean };

export interface Watch {
  unwatch(): void;
}

/**
 *
 */
interface WatchImpl<T> extends Derivative {
  [privates.subscriber]: Watcher<T>;
}

const getImpl = getFromRegistry as <T>(ref: Watch) => WatchImpl<T>;

function handleUpdate<T extends AnyRef[], S>(this: Watch, to: S, from: S, sourceRef: Ref<S>): void {
  const impl = getImpl<T>(this);

  /*                                         */
  const sortedResult = Array.from(impl[privates.deps].values()).reduce(
    (results, cRef) => {
      return fillWatches(results, cRef, sourceRef, to, from);
    },
    [[], []] as [MapSources<T>, MapSources<T>]
  );

  /*                                                         */
  impl[privates.subscriber](...sortedResult);
}

function unwatch<T extends AnyRef[]>(this: Watch): void {
  const impl = getImpl<T>(this);

  impl[privates.deps].forEach((d) => {
    const refImpl = getRefImpl(d);
    refImpl[privates.unsub](d, handleUpdate, { thisVal: this });
  });
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function watch<T extends AnyRef[]>(
  watcher: Watcher<T>,
  watched: [...T],
  options?: WatchOptions
): Watch {
  const res: Watch = { unwatch };

  const impl: WatchImpl<T> = {
    [privates.deps]: new Set(watched),
    [privates.subscriber]: watcher,
  };

  refRegistry.set(res, impl);

  watched.forEach((r) => {
    const refImpl = getRefImpl(r);
    refImpl[privates.sub](r, handleUpdate, { thisVal: res });
  });

  if (options?.immediate) {
    watcher(...unrefWatch(watched));
  }

  return res;
}

/**
 *
 *
 *
 *
 *
 *
 */
export function watchImmediate<T extends AnyRef[]>(
  watcher: Watcher<T>,
  watched: [...T],
  options?: WatchOptions
): Watch {
  return watch(watcher, watched, { ...options, immediate: true });
}
