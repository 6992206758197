import { BuilderFunction } from "../dom";
import { Ref } from "../react";

const map = new WeakMap<Element, Ref<ResizeObserverEntry | undefined> | undefined>();

/*                                     */
const observer = new ResizeObserver((entries) => {
  entries.forEach((e) => {
    const refval = map.get(e.target);
    if (refval) {
      refval.value = e;
    }
  });
});

export function domOnResize<E extends Element>(data: {
  /**
 *
 *
 */
  resizeEntry: Ref<ResizeObserverEntry | undefined>;
}): BuilderFunction<E> {
  /*                           */
  /*                        */
  /*                     */
  /*                                     */
  /*                                             */
  /*  */
  /* */

  return (element) => {
    map.set(element, data.resizeEntry);
    observer.observe(element);
  };
}
