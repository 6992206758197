import { BuilderFunction, ElementBuilder, ToElement } from "./builder";
import { getElementBuilder } from "./builder.internal";
import { setClassNameIfGiven } from "./internal";

function fillArray<T>(
  howMany: number,
  tagName: T,
  className?: string
): { tag: T; class: string | undefined }[] {
  return Array<{ tag: T; class: string | undefined }>(howMany).fill({
    tag: tagName,
    class: className,
  });
}

function createElementInternal<Type extends keyof HTMLElementTagNameMap | HTMLElement>(params: {
  tag: Type extends HTMLElement ? string | keyof HTMLElementTagNameMap : Type;
  class: string | undefined;
}): ToElement<Type> {
  return setClassNameIfGiven(document.createElement(params.tag) as ToElement<Type>, params.class);
}

function createElementBuilderInternal<
  Type extends keyof HTMLElementTagNameMap | HTMLElement
>(params: {
  tag: Type extends HTMLElement ? string | keyof HTMLElementTagNameMap : Type;
  class: string | undefined;
}): ElementBuilder<ToElement<Type>> {
  return getElementBuilder(createElementInternal(params));
}

/**
 *
 *
 *
 *
 *
 *
 *
 */
export function domCreate<Type extends keyof HTMLElementTagNameMap | HTMLElement>(
  tagName: Type extends HTMLElement ? keyof HTMLElementTagNameMap : Type,
  className?: string
): ElementBuilder<ToElement<Type>> {
  return createElementBuilderInternal({ tag: tagName, class: className });
}

/**
 *
 *
 *
 *
 */
export function domCreateCustom<Type extends HTMLElement>(
  tagName: string,
  className?: string
): ElementBuilder<ToElement<Type>> {
  return createElementBuilderInternal<Type>({ tag: tagName as never, class: className });
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function domCreateMany<Type extends keyof HTMLElementTagNameMap | HTMLElement>(
  howMany: number,
  tagName: Type extends HTMLElement ? string | keyof HTMLElementTagNameMap : Type,
  className?: string
): ElementBuilder<ToElement<Type>>[] {
  return fillArray(howMany, tagName, className).map(createElementBuilderInternal);
}

/**
 *
 *
 *
 *
 */
export function domComment(data: unknown): ElementBuilder<Comment> {
  return getElementBuilder(document.createComment(`${data}`));
}

/**
 *
 *
 *
 *
 */
export function domText(data?: string | BuilderFunction<Text>): ElementBuilder<Text> {
  const builder = getElementBuilder(document.createTextNode(typeof data === "string" ? data : ""));
  return typeof data === "function" ? builder(data) : builder;
}
