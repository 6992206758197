import { bemBlock, bemElement } from "../../common/src/bem";
import { patternName } from "./pattern";

/**
 *
 *
 *
 *
 *
 */
const mainModifiers = ["ready", "fallback", "no-scroll-smoothing", "no-arrows"] as const;

const container = bemElement("container");

/**
 *
 */
const stage = bemElement("stage");

/**
 *
 *
 *
 *
 *
 *
 */
const slide = bemElement("slide", "active");

const pageIndicator = bemElement("page-indicator");
const pageIndicatorFlex = bemElement("page-indicator-flex");
const pageIndicatorWrapper = bemElement("page-indicator-wrapper");
const pageIndicatorItem = bemElement("page-indicator-item", "big", "active");
/**
 *
 *
 *
 *
 *
 *
 *
 *
 */
const arrow = bemElement("arrow", "hidden", "airbag", "next");

export const classes = bemBlock(patternName, ...mainModifiers)(
  container,
  slide,
  stage,
  arrow,
  pageIndicator,
  pageIndicatorFlex,
  pageIndicatorWrapper,
  pageIndicatorItem
);

export type PatternClasses = typeof classes;
