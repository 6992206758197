import { assert } from "../utils";
import {
  AnyChildElement,
  AnyParentElement,
  ElementBuilder,
  ElementSafeBuilder,
  ToElement,
} from "./builder";
import { getElementBuilder, getElementSafeBuilder } from "./builder.internal";
import { getBuilderToElement } from "./internal";

/**
 *
 *
 *
 *
 *
 *
 */
export function domWith<E extends AnyChildElement | AnyParentElement = HTMLElement>(
  element: E
): ElementBuilder<E> {
  return getElementBuilder(element);
}

/**
 *
 *
 */
export const domBody = domWith(document.getElementsByTagName("body")[0]);

/**
 *
 *
 */
export const domDocument = domWith(document);

/**
 *
 *
 */
export const domHtml = domWith(document.documentElement);

/**
 *
 *
 *
 *
 */
export function domWithParent<E extends Element, P extends Element = HTMLElement>(
  element: E | ElementBuilder<E>
): ElementBuilder<P> {
  return domWith<P>(getBuilderToElement(element).parentNode as never);
}

/**
 *
 *
 *
 *
 *
 */
export function domWithMany<E extends Element = HTMLElement>(elements: E[]): ElementBuilder<E>[] {
  return elements.map(getElementBuilder);
}

/**
 *
 *
 *
 *
 *
 *
 */
function elementFromQuerySafe<
  E extends Element = HTMLElement,
  P extends Element | ParentNode = Element
>(selector: string, parent: P | ElementBuilder<P> | Document = document): E | undefined {
  return getBuilderToElement(parent).querySelector<E>(selector) as never;
}

/**
 *
 *
 *
 *
 *
 *
 *
 */
function elementFromQuery<
  E extends Element = HTMLElement,
  P extends Element | ParentNode = Element
>(selector: string, parent: P | ElementBuilder<P> | Document = document): E {
  const element = elementFromQuerySafe<E, P>(selector, parent);
  assert(element, `could not retrieve element with selector: ${selector}`);
  return element;
}

/**
 *
 *
 *
 *
 *
 *
 *
 */
export function domQuery<E extends Element = HTMLElement, P extends Element | ParentNode = Element>(
  selector: string,
  parent: P | ElementBuilder<P> | Document = document
): ElementBuilder<E> {
  return getElementBuilder(elementFromQuery<E, P>(selector, parent));
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function domQuerySafe<
  E extends Element = HTMLElement,
  P extends Element | ParentNode = Element
>(
  selector: string,
  parent: P | ElementBuilder<P> | Document = document
): ElementSafeBuilder<E | undefined> {
  return getElementSafeBuilder(elementFromQuerySafe(selector, getBuilderToElement(parent)));
}

function elementsFromQueryAll<
  E extends Element = HTMLElement,
  P extends Element | ParentNode = Element
>(selector: string, parent: P | ElementBuilder<P> | Document = document): E[] {
  return Array.from(getBuilderToElement(parent).querySelectorAll<E>(selector));
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function domQueryAll<
  E extends Element = HTMLElement,
  P extends Element | ParentNode = Element
>(
  selector: string,
  parent: P | ElementBuilder<P> | Document = document
): ElementBuilder<ToElement<E>>[] {
  return elementsFromQueryAll<ToElement<E>, P>(selector, parent).map(getElementBuilder);
}
