import type { AnyArgs } from "../definitions";
import * as privates from "./privates";

export type EventSubscriber<T extends AnyArgs, This> =
  /**
 *
 *
 *
 *
 */
  (this: This, ...args: [...payload: T, sender: Event<T>]) => void;

export type EventSubOptions<This> = {
  /**
 *
 */
  once?: boolean;
  /**
 *
 */
  thisVal?: This;
};

type SubRecord<T extends AnyArgs> = {
  cb: EventSubscriber<T, unknown>;
  options?: EventSubOptions<unknown>;
};

export interface Event<T extends AnyArgs = AnyArgs> {
  /**
 *
 */
  [privates.subscriber]: Set<SubRecord<T>>;
  /**
 *
 *
 *
 *
 */
  sub<This>(cb: EventSubscriber<T, This>, options?: EventSubOptions<This>): Event<T>;

  /**
 *
 *
 *
 */
  emit(...payload: T): Event<T>;

  /**
 *
 *
 */
  unsub<This>(cb: EventSubscriber<T, This>, options?: EventSubOptions<This>): Event<T>;
}

function sub<T extends AnyArgs, This>(
  this: Event<T>,
  cb: EventSubscriber<T, This>,
  options?: EventSubOptions<This>
): Event<T> {
  this[privates.subscriber].add({ cb: cb as EventSubscriber<T, unknown>, options });
  return this;
}

function unsub<T extends AnyArgs, This>(
  this: Event<T>,
  cb: EventSubscriber<T, This>,
  options?: EventSubOptions<This>
): Event<T> {
  this[privates.subscriber].forEach((s) => {
    if (
      s.cb === cb &&
      (!options ||
        (s.options && s.options.once === options.once && s.options.thisVal === options.thisVal))
    ) {
      this[privates.subscriber].delete(s);
    }
  });
  return this;
}

const emitSubscriber = <T extends AnyArgs>(
  subs: Set<SubRecord<T>>,
  s: SubRecord<T>,
  payload: T,
  sender: Event<T>
): void => {
  if (s.options?.once) {
    subs.delete(s);
  }

  s.cb.call(s.options?.thisVal, ...payload, sender);
};

function emit<T extends AnyArgs>(this: Event<T>, ...payload: T): Event<T> {
  this[privates.subscriber].forEach((s) =>
    emitSubscriber(this[privates.subscriber], s, payload, this)
  );
  return this;
}

/**
 *
 *
 *
 */
export function signalEvent<T extends AnyArgs, This = unknown>(
  cb?: EventSubscriber<T, This>,
  options?: EventSubOptions<This>
): Event<T> {
  /*                           */
  const res: Event<T> = { [privates.subscriber]: new Set(), sub, emit, unsub };

  /*                                              */
  return cb ? res.sub(cb, options) : res;
}
