import { BuilderFunction } from "../../../common/src/dom";
import { ref, Ref, watchImmediate } from "../../../common/src/react";
import { getChildren } from "../../../common/src/utils";
import { IndexedHTMLElement } from "../types";

/**
 *
 *
 *
 *
 *
 *
 */
export function isIntersecting(entry: IntersectionObserverEntry, threshold: number): boolean {
  return entry.isIntersecting && entry.intersectionRatio >= threshold;
}

/**
 *
 *
 *
 *
 */
export function sortObserverEntries(
  scrollingRight: boolean,
  a: IntersectionObserverEntry,
  b: IntersectionObserverEntry
): number {
  const indexA = (a.target as IndexedHTMLElement).elementIndex;
  const indexB = (b.target as IndexedHTMLElement).elementIndex;
  return scrollingRight ? indexA - indexB : indexB - indexA;
}

type ObservationData = {
  scrollingRight: Ref<boolean>;
  lastScrollLeft: Ref<number>;
  activeSlide: Ref<number>;
  visibleSlideIndices: Set<number>;
  stage: HTMLElement;
  intersectionThreshold: Ref<number>;
  visibleSlidesCount: Ref<number>;
  totalSlides: Ref<number>;
};

export function processUpdatedEntry(data: ObservationData, entry: IntersectionObserverEntry): void {
  const { visibleSlideIndices } = data;
  const { elementIndex } = entry.target as IndexedHTMLElement;

  const newActiveSlide = data.scrollingRight.value
    ? /*                                               */
      elementIndex - data.visibleSlidesCount.value + 1
    : /*                          */
      elementIndex;

  if (
    isIntersecting(entry, data.intersectionThreshold.value) &&
    newActiveSlide >= 0 &&
    newActiveSlide < data.totalSlides.value
  ) {
    /*                          */
    visibleSlideIndices.add(elementIndex);

    /*                         */
    data.activeSlide.value = newActiveSlide;
    return;
  }

  /*                                       */
  visibleSlideIndices.delete(elementIndex);
}

/**
 *
 */
export function processEntries(data: ObservationData, entries: IntersectionObserverEntry[]): void {
  const { scrollingRight, lastScrollLeft, stage } = data;

  /*                                                                                        */
  /*                                                                                                     */
  if (!stage.matches("body *") || stage.scrollLeft === lastScrollLeft.value) {
    return;
  }

  /*                             */
  /*            */
  /*                                   */
  /*                          */
  scrollingRight.value = lastScrollLeft.value < stage.scrollLeft;
  /*                          */
  lastScrollLeft.value = stage.scrollLeft;

  /*                      */
  entries
    /*                                                         */
    .sort((a, b) => sortObserverEntries(scrollingRight.value, a, b))
    .forEach((entry) => processUpdatedEntry(data, entry));
}

export function createObserver(
  callback: IntersectionObserverCallback,
  stage: HTMLElement,
  slides: HTMLElement[],
  threshold: number
): IntersectionObserver {
  const observerOptions = { root: stage, threshold };

  /*                       */
  const res = new IntersectionObserver(callback, observerOptions);
  slides.forEach((slide) => res.observe(slide));

  return res;
}

export function domStageObserver(input: {
  activeSlide: Ref<number>;
  intersectionThreshold: Ref<number>;
  visibleSlidesCount: Ref<number>;
  visibleSlideIndices: Set<number>;
  scrollingRight: Ref<boolean>;
  totalSlides: Ref<number>;
}): BuilderFunction<HTMLElement> {
  const { intersectionThreshold } = input;

  const observer = ref<IntersectionObserver>();
  /**
 *
 */
  const lastScrollLeft = ref(0);

  return (stage) => {
    lastScrollLeft.value = stage.scrollLeft;
    const slides = getChildren<IndexedHTMLElement>(stage);

    /*                                                     */
    watchImmediate(() => {
      /*                        */
      if (observer.value) {
        observer.value.disconnect();
      }

      const data = { ...input, lastScrollLeft, stage, slides };

      const callback = (entries: IntersectionObserverEntry[]): void =>
        processEntries(data, entries);

      observer.value = createObserver(callback, stage, slides, intersectionThreshold.value);
    }, [intersectionThreshold]);
  };
}
