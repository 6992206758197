import { domQueryAll } from "../dom";
import { eventName, eventQBus } from "./head";
import { globalEvents } from "../events.async";
import type { PatternConstructor } from "./construct";
import type { AnyPatternDefinition } from "./definition";
import { AnyProps } from "../component/props";
import { EmptyEvents, EmptyRecord } from "../definitions";
import { MethodDefs } from "./methods";
import { PatternDomContext } from "./context";

export type InitOptions = {
  /**
 *
 *
 */
  baseSelector: string;
  /**
 *
 *
 *
 *
 *
 */
  updateSelector?: (eventSelector: string) => string;
};

export function registerGlobalInit<
  Name extends string,
  Root extends HTMLElement,
  Props extends AnyProps,
  Data extends EmptyRecord,
  Accept extends EmptyEvents,
  Provide extends EmptyRecord,
  Methods extends MethodDefs<PatternDomContext<string, Root, Props, Data, Provide>>,
>(
  definition: AnyPatternDefinition,
  constructor: PatternConstructor<Name, Root, Props, Accept, Provide, Methods>,
): void {
  /*                                    */
  const { name, init } = definition;
  if (init === false) {
    return;
  }

  const initEvent = eventName(name, "init");

  const config =
    init === undefined || init === true
      ? { baseSelector: `body .js_pl_${name}:not([data-${name}-skip-init])` }
      : init;

  /*                    */

  /*                       */
  eventQBus.on(initEvent, (eventSelector: string) => {
    const selectorName = config.updateSelector?.(eventSelector) || eventSelector;
    domQueryAll(selectorName).forEach((e) => {
      constructor({} as never, e as unknown as Root);
    });
  });

  /*                                            */
  globalEvents.on("assets.events.documentReady", () => {
    eventQBus.emit(initEvent, config.baseSelector);
  });
}
