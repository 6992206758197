import { AnyFunction, EmptyRecord } from "../definitions";
import { domAddListenerCustom } from "../dom";
import { Component, ComponentBuidlerFunction } from "./component";
import { AnyComponentContext } from "./context";
import { componentToElement } from "./utils";

export type AnyEmits = Record<string, EmptyRecord>;

/*                                                          */
export type AnyComponentEmits = Record<string, (data: any) => void>;

export type ComponentEmits<Emits extends AnyEmits> = {
  [P in keyof Emits]: (data: Emits[P]) => void;
};

export type EmitNames<Emits extends AnyEmits> = (keyof Emits)[];

/**
 *
 *
 *
 *
 */
export function defineEmits<Emits extends AnyEmits>(...emits: (keyof Emits)[]): EmitNames<Emits> {
  return emits;
}

export function dispatchEvent(
  root: HTMLElement,
  type: string,
  event: EmptyRecord | CustomEventInit<EmptyRecord>
): void {
  const init: CustomEventInit<EmptyRecord> =
    "detail" in event && typeof event.detail !== "undefined"
      ? event
      : { detail: event, bubbles: false };
  root.dispatchEvent(new CustomEvent(type, init));
}

export function createComponentEmits<Emits extends AnyEmits>(
  context: AnyComponentContext,
  emits?: EmitNames<Emits>
): ComponentEmits<Emits> {
  if (!emits) {
    return {} as never;
  }

  const res = emits.map((e) => [
    e,
    (data: EmptyRecord | CustomEventInit<EmptyRecord>) => {
      const element = componentToElement.get(context);

      if (element) {
        dispatchEvent(element, e as string, data);
      }
    },
  ]);

  return Object.freeze(Object.fromEntries(res)) as never;
}

/**
 *
 */
export const domOn: <Emits extends Record<string, AnyFunction>, Type extends keyof Emits>(
  type: Type,
  listenerCb: (ev: CustomEvent<Parameters<Emits[Type]>[0]>) => void,
  options?: boolean | AddEventListenerOptions
  /*                                                          */
) => ComponentBuidlerFunction<Component<string, any, any, Emits>, any> =
  domAddListenerCustom as never;
