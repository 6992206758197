/*                     */

export function assert<V>(value: V, message: string): asserts value {
  if (!value) {
    throw new Error(message);
  }
}

export function ensure<V>(value: V, message: string): NonNullable<V> {
  assert(value, message);
  return value;
}
