import { AnyProps, DecomposeProp, parseAttributeProp, Properties } from "../component/props";
import { watch } from "../react";
import { camelcasify, capitalize, kebabcasify } from "../utils/string";
import { AnyPatternDefinition } from "./definition";
import { AnyPattern } from "./pattern";
import { elementToPattern } from "./registry";

const registry = new WeakMap<HTMLElement, AnyProps>();

/*                                     */
const observer = new MutationObserver((entries) => {
  entries.forEach((e) => {
    if (e.type !== "attributes" || !e.attributeName) {
      return;
    }

    const instance = elementToPattern.get(e.target as AnyPattern);
    if (!instance) {
      return;
    }

    const propDefs = registry.get(e.target as AnyPattern);
    if (!propDefs) {
      return;
    }

    const propName = camelcasify(e.attributeName.replace(`data-${instance.name}-`, ""));
    if (!propDefs[propName]) {
      return;
    }

    const value = (e.target as AnyPattern).getAttribute(e.attributeName);
    const val = parseAttributeProp(propDefs[propName], value);
    if (val === undefined) {
      return;
    }

    instance.props[propName].value = val;
  });
});

export function readDataOptions<Props extends AnyProps>(
  rootElement: HTMLElement,
  definition: AnyPatternDefinition,
  props: Properties<Props>
): void {
  if (!definition.props) {
    return;
  }

  const attributeFilter = Object.entries(definition.props).map(([key, defVal]) => {
    /*                                                        */
    const optionsName = definition.dataName ?? definition.name;

    /*                                  */
    const datasetPropName = camelcasify(optionsName) + capitalize(key);

    /*                    */
    const val = parseAttributeProp<DecomposeProp<Props[string]>>(
      defVal,
      rootElement.dataset[datasetPropName]
    );

    /*                                  */
    if (val !== undefined) {
      props[key].value = val;
    }

    watch(
      (to) => {
        const stringified = to.toString();
        if (rootElement.dataset[datasetPropName] !== stringified) {
          rootElement.dataset[datasetPropName] = stringified;
        }
      },
      [props[key]]
    );

    return `data-${optionsName}-${kebabcasify(key)}`;
  });

  registry.set(rootElement, definition.props);
  observer.observe(rootElement, { attributes: true, attributeFilter });
}
