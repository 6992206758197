/**
 *
 */
export const subscriber = Symbol("");

/**
 *
 */
export const signal = Symbol("");

/**
 *
 */
export const value = Symbol("");

/**
 *
 */
export const deps = Symbol("");

/**
 *
 */
export const detected = Symbol("");

/**
 *
 */
export const getter = Symbol("");

/**
 *
 */
export const setter = Symbol("");

/**
 *
 */
export const sub = Symbol("");

/**
 *
 */
export const unsub = Symbol("");
