import { Ref, RefImpl, RefSubscriber, RefSubOptions, getRefImpl } from "./ref";
import { trackingContext, Derivative, refRegistry, RefSymbol, getFromRegistry } from "./internal";
import { signalEvent } from "./event";
import * as privates from "./privates";
import { assert } from "../utils/assert";

/**
 *
 *
 *
 *
 *
 *
 */
export type ComputedRef<T> = Ref<T>;

export type ComputeFn<T> = () => T;

export type ComputeProp<T> = { get: () => T; set: (value: T) => void };

export type Compute<T> = ComputeFn<T> | ComputeProp<T>;

export interface ComputedRefImpl<T> extends RefImpl<T>, Derivative {
  [privates.detected]: boolean;
  [privates.getter]: ComputeFn<T>;
  [privates.setter]?: (value: T) => void;
}

const getImpl = getFromRegistry as <T>(ref: ComputedRef<T>) => ComputedRefImpl<T>;

function run<T>(this: ComputedRef<T>): void {
  const impl = getImpl(this);
  const from = impl[privates.value];
  const to = this.value;

  /*                           */
  if (from !== to) {
    impl[privates.signal].emit(this.value, from, this);
  }
}

function getValue<T>(this: ComputedRef<T>): T {
  const impl = getImpl(this);

  /*                                                                  */
  /*                            */
  const current = trackingContext[trackingContext.length - 1];
  if (current) {
    current[privates.deps].add(this);
  }

  /*                                                  */
  const isDetecting = !impl[privates.detected] && !trackingContext.includes(impl);
  if (isDetecting) {
    /*                              */
    trackingContext.push(impl);
  }

  /*                                                   */
  /*                                                                          */
  /*                                   */
  impl[privates.value] = impl[privates.getter]();

  if (isDetecting) {
    /*                                                                */
    impl[privates.deps].forEach((dep) => {
      const depImpl = getRefImpl(dep);
      depImpl[privates.sub](dep, run, { thisVal: this });
    });

    /*                           */
    impl[privates.detected] = true;

    /*                   */
    trackingContext.splice(trackingContext.indexOf(impl), 1);
  }

  return impl[privates.value];
}

function setValue<T>(this: ComputedRef<T>, value: T): void {
  const impl = getImpl(this);
  assert(impl[privates.setter], "this computed instance is read only");
  impl[privates.setter](value);
}

function sub<T, This>(
  ref: ComputedRef<T>,
  cb: RefSubscriber<T, This>,
  options?: RefSubOptions<This> | undefined
): ComputedRefImpl<T> {
  const impl = getImpl(ref);

  impl[privates.signal].sub(cb, options);

  if (options?.immediate) {
    const { value } = ref;
    cb.call(options.thisVal as never, value, value, ref);
  }

  return impl;
}

function unsub<T, This>(
  ref: ComputedRef<T>,
  cb: RefSubscriber<T, This>,
  options?: RefSubOptions<This> | undefined
): ComputedRefImpl<T> {
  const impl = getImpl(ref);

  impl[privates.signal].unsub(cb, options);

  return impl;
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function computed<T>(compute: Compute<T>): ComputedRef<T> {
  const res = { [RefSymbol]: true } as ComputedRef<T>;
  Object.defineProperty(res, "value", { get: getValue, set: setValue });

  const impl: ComputedRefImpl<T> = {
    [privates.signal]: signalEvent(),
    [privates.value]: undefined as never,
    [privates.deps]: new Set(),
    [privates.detected]: false,
    [privates.getter]: typeof compute === "function" ? compute : compute.get,
    [privates.setter]: typeof compute !== "function" ? compute.set : undefined,
    [privates.sub]: sub,
    [privates.unsub]: unsub,
  };

  refRegistry.set(res, impl);
  return res;
}
