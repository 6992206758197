import { domClass, domListener, domProps, domWith } from "../../../common/src/dom";
import { computed, Ref } from "../../../common/src/react";
import { hasClass } from "../../../common/src/utils";
import { classes } from "../classes";
import { IndexedHTMLElement } from "../types";

export function mapSlides(data: {
  stage: HTMLElement;
  /**
 *
 */
  activeSlide: Ref<number>;
  totalSlides: Ref<number>;
}) {
  let initialActiveSlide = 0;
  /*             */
  /*                                  */
  const slides = Array.from<IndexedHTMLElement>(data.stage.children as never).map((slide, i) => {
    /*                                         */

    if (hasClass(slide, classes.slideActive)) {
      initialActiveSlide = i;
    }

    const res = domWith(slide)(
      /**
 *
 *
 */
      domProps({ elementIndex: i }),
      domClass({
        /*                             */
        [classes.slide]: true,
        /*                           */
        [classes.slideActive]: computed(() => data.activeSlide.value === i),
      })
    );

    return res;
  });

  data.totalSlides.value = slides.length;

  return { slides, initialActiveSlide };
}
