import type { CamelCase, PascalCase } from "type-fest";

/**
 *
 *
 *
 *
 */
export function capitalize<Value extends string>(value: Value): Capitalize<Value> {
  return value[0].toUpperCase().concat(value.slice(1)) as never;
}

/**
 *
 */
export function uncapitalize<Value extends string>(value: Value): Uncapitalize<Value> {
  return value[0].toLowerCase().concat(value.slice(1)) as never;
}

/**
 *
 *
 *
 *
 *
 */
export function camelcasify<Value extends string>(value: Value): CamelCase<Value> {
  const [first, ...rest] = value.split(/[-_]/g);
  return uncapitalize(first).concat(...rest.map((r) => capitalize(r))) as never;
}

/**
 *
 *
 *
 *
 *
 */
export function pascalcasify<Value extends string>(value: Value): PascalCase<Value> {
  return "".concat(...value.split(/[-_]/g).map((r) => capitalize(r))) as never;
}

export function kebabcasify(value: string): string {
  return value.replace(/[A-Z]/g, (match, offset) => {
    if (offset === 0) {
      return match.toLowerCase();
    }
    return `-${match.toLowerCase()}`;
  });
}
