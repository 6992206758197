import { domOnResize } from "../../../common/src/directives/onResize";
import { domClass, domListener, domRemoveClasses, ElementBuilder } from "../../../common/src/dom";
import { computed, Ref } from "../../../common/src/react";
import { classes } from "../classes";

/**
 *
 *
 *
 */
/*                                             */
export function hasScrollBehaviour(windowObject: Window): boolean {
  return "scrollBehavior" in windowObject.document.documentElement.style;
}

/**
 *
 *
 *
 *
 *
 */
export function mapRoot(data: {
  element: ElementBuilder<HTMLElement>;
  stageCanScroll: Ref<boolean>;
  keyUp: (ev: KeyboardEvent) => void;
  resizeEntry: Ref<ResizeObserverEntry | undefined>;
  moveSnap: Ref<boolean>;
}): ElementBuilder<HTMLElement> {
  const { element, stageCanScroll, keyUp } = data;

  const root = element(
    /*                                                                          */
    domRemoveClasses(classes.mainReady),

    domClass({
      [classes.main]: true,
      [classes.mainNoArrows]: computed(() => !stageCanScroll.value),
      [classes.mainFallback]: !hasScrollBehaviour(window),
      [classes.mainNoScrollSmoothing]: data.moveSnap,
    }),

    domListener(stageCanScroll, "keyup", keyUp),

    domOnResize(data)
  );

  return root;
}
