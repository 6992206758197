import type { AnyRef, Ref } from "./ref";
import * as privates from "./privates";
import type { AnyArgs } from "../definitions";

/**
 *
 *
 */
export const refRegistry = new WeakMap();

export function getFromRegistry<R extends object, I>(ref: R): I {
  return refRegistry.get(ref);
}

/*                                      */
export const RefSymbol = Symbol("");

/**
 *
 */
export type Derivative = { [privates.deps]: Set<AnyRef> };

/**
 *
 *
 *
 *
 *
 */
export const trackingContext = [] as Derivative[];

/**
 *
 */
export type MapSources<T> = {
  [K in keyof T]: T[K] extends Ref<infer V> ? V : T[K] extends object ? T[K] : never;
};

/**
 *
 *
 *
 *
 *
 *
 */
export function fillWatch<T>(
  res: [AnyArgs, AnyArgs],
  /*                                                          */
  to: any,
  /*                                                          */
  from: any
): [MapSources<T>, MapSources<T>] {
  res[0].push(to);
  res[1].push(from);
  return res as never;
}

/**
 *
 *
 *
 */
export function unrefWatch<T extends AnyRef[]>(refs: [...T]): [MapSources<T>, MapSources<T>] {
  const extracted = refs.map((r) => r.value) as MapSources<T>;
  return [extracted, extracted];
}

export function fillWatches<T extends AnyRef[], S>(
  result: [MapSources<T>, MapSources<T>],
  storedRef: Ref<S>,
  sourceRef: Ref<S>,
  to: S,
  from: S
): [MapSources<T>, MapSources<T>] {
  if (storedRef === sourceRef) {
    /*                                    */
    return fillWatch(result, to, from);
  }

  /*                           */
  return fillWatch(result, storedRef.value, storedRef.value);
}
