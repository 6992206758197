import { EmptyRecord } from "../definitions";

/**
 *
 *
 *
 *
 *
 */
export function defineProperty<T extends EmptyRecord>(
  o: T,
  p: PropertyKey,
  /*                                                          */
  attributes: PropertyDescriptor & ThisType<any>
): T {
  return Object.defineProperty(o, p, { enumerable: false, configurable: false, ...attributes });
}
