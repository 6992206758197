/*                                                   */
import type { AnyArgs, AnyFunction } from "../definitions";
import { getElementBuilder } from "../dom/builder.internal";
import { AnyPatternContext } from "./context";
import { AnyPattern } from "./pattern";
import { elementToPattern } from "./registry";

/**
 *
 */
export type MethodDefs<Context extends AnyPatternContext> = Record<
  string,
  ({ root, props, data, emit, name }: Context, ...args: AnyArgs) => any
>;

/**
 *
 */
export type AnyMethodDefs = MethodDefs<any>;

/**
 *
 *
 */
export type AnyPatternMethods = Record<string, AnyFunction>;

/**
 *
 */
type FunctionWithoutContext<P extends AnyFunction> = P extends (
  ...args: [any, ...infer A]
) => infer R
  ? (...args: A) => R
  : never;

/**
 *
 *
 */
export type PatternMethods<Methods extends AnyMethodDefs> = {
  [P in keyof Methods]: FunctionWithoutContext<Methods[P]>;
};

export function getMethods(element: AnyPattern, methods: AnyMethodDefs): AnyPatternMethods {
  const context = elementToPattern.get(element);

  /*                                                  */
  return context
    ? Object.fromEntries(
        Object.entries(methods).map(([key, method]) => [
          key,
          (...args: AnyArgs) => method({ ...context, root: getElementBuilder(element) }, ...args),
        ])
      )
    : {};
}
