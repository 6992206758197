import { Event, signalEvent, EventSubOptions } from "./event";
import { getFromRegistry, refRegistry, RefSymbol, trackingContext } from "./internal";
import * as privates from "./privates";

export type RefSubscriber<T extends unknown | undefined, This> =
  /**
 *
 */
  (this: This, to: T, from: T, source: Ref<T>) => void;

export type RefSubOptions<This> = EventSubOptions<This> & {
  /**
 *
 *
 */
  immediate?: boolean;
};

/**
 *
 */
/*                                                          */
export type AnyRef = Ref<any>;

export interface Ref<T> {
  /**
 *
 */
  get value(): T;
  set value(val: T);
  /*                     */
  [RefSymbol]: true;
}

export interface RefImpl<T> {
  /**
 *
 */
  [privates.signal]: Event<[T, T, Ref<T>]>;

  /**
 *
 */
  [privates.value]: T;

  /**
 *
 *
 *
 *
 *
 */
  [privates.sub]<This>(
    ref: Ref<T>,
    cb: RefSubscriber<T, This>,
    options?: RefSubOptions<This>
  ): RefImpl<T>;

  /**
 *
 *
 *
 *
 *
 */
  [privates.unsub]<This>(
    ref: Ref<T>,
    cb: RefSubscriber<T, This>,
    options?: RefSubOptions<This>
  ): RefImpl<T>;
}

export const getRefImpl = getFromRegistry as <T>(ref: Ref<T>) => RefImpl<T>;

function sub<T, This>(
  ref: Ref<T>,
  cb: RefSubscriber<T, This>,
  options?: RefSubOptions<This> | undefined
): RefImpl<T> {
  const impl = getRefImpl(ref);

  impl[privates.signal].sub(cb, options);

  /*                                                                */
  /*     */
  if (options?.immediate) {
    const { value } = ref;
    cb.call(options.thisVal as never, value, value, ref);
  }

  return impl;
}

function unsub<T, This>(
  ref: Ref<T>,
  cb: RefSubscriber<T, This>,
  options?: RefSubOptions<This> | undefined
): RefImpl<T> {
  const impl = getRefImpl(ref);
  impl[privates.signal].unsub(cb, options);
  return impl;
}

function getValue<T>(this: Ref<T>): T {
  const impl = getRefImpl(this);

  /*                                                                  */
  /*                            */
  const current = trackingContext[trackingContext.length - 1];
  if (current) {
    current[privates.deps].add(this);
  }

  return impl[privates.value];
}

function setValue<T>(this: Ref<T>, value: T): void {
  const impl = getRefImpl(this);

  const from = impl[privates.value];
  impl[privates.value] = value;

  if (from !== value) {
    impl[privates.signal].emit(value, from, this);
  }
}

/**
 *
 */
export type Unref<T> = T extends Ref<infer U> ? U : T;

/**
 *
 *
 *
 */
export function isRef<T>(value: Ref<T> | T | never): value is Ref<T> {
  return (
    value !== null &&
    typeof value === "object" &&
    RefSymbol in value &&
    (value as Ref<T>)[RefSymbol] === true
  );
}

/**
 *
 *
 *
 *
 */
export function ref<T>(): Ref<T | undefined>;
/**
 *
 *
 *
 *
 */
export function ref<T>(initial: T | Ref<T>): Ref<T>;
export function ref<T>(initial?: T | Ref<T>): Ref<T> {
  const res = { [RefSymbol]: true } as Ref<T>;
  Object.defineProperty(res, "value", { set: setValue, get: getValue });

  const impl: RefImpl<T> = {
    [privates.signal]: signalEvent<[T, T, Ref<T>]>(),
    [privates.value]: (isRef(initial) ? initial.value : initial) as T,
    [privates.sub]: sub,
    [privates.unsub]: unsub,
  };

  refRegistry.set(res, impl);

  return res;
}
