import { defineComponent, defineEmits, PropType } from "../../../common/src/component";
import { domMapComponent } from "../../../common/src/component/utils";
import {
  domAddListener,
  domAppend,
  domAttrs,
  domClass,
  domPatternIcon,
} from "../../../common/src/dom";
import { ref, watchEffect } from "../../../common/src/react";
import { classes } from "../classes";

/**
 *
 */
export type ArrowClickSource = "arrowLeft" | "arrowRight";

/**
 *
 *
 *
 */
const delay = 500;

const dataMap = {
  left: {
    icon: "arrow-left",
    source: "arrowLeft",
  },
  right: {
    icon: "arrow-right",
    source: "arrowRight",
  },
} as const;

export type ArrowNextSlideEventDetail = {
  sourceType: ArrowClickSource;
};

/**
 *
 */
export type ArrowNextSlideEvent = CustomEvent<ArrowNextSlideEventDetail>;

/**
 *
 */
export const Arrow = defineComponent({
  name: "arrow",

  props: {
    arrowType: { type: String as PropType<keyof typeof dataMap>, required: true },
    /**
 *
 *
 *
 *
 *
 *
 *
 */
    airbag: { type: Boolean, default: false },
  },

  root: {
    tag: "button",
    type: HTMLButtonElement,
  },

  emits: defineEmits<{
    /**
 *
 */
    nextSlide: ArrowNextSlideEventDetail;
  }>("nextSlide"),

  setup({ emit, props }) {
    /*                  */
    const onClick = (ev: PointerEvent): void => {
      /*                   */
      ev.preventDefault();
      emit.nextSlide({ sourceType: dataMap[props.arrowType.value].source });
    };

    /*                                             */
    /*                                                                               */
    /*                                                             */
    const doAirbag = ref(false);
    watchEffect(() => {
      if (props.airbag.value) {
        doAirbag.value = true;
        window.setTimeout(() => {
          doAirbag.value = false;
        }, delay);
      }
    });

    return { onClick, doAirbag };
  },

  dom({ data, props, root }) {
    root(
      domAttrs({ type: "button" }),
      domClass({
        [classes.arrow]: true,
        pl_rounded50: true,
        "pl_rounded50--elevated": true,
        [classes.arrowHidden]: props.airbag,
        [classes.arrowNext]: props.arrowType.value === "right",
        [classes.arrowAirbag]: data.doAirbag,
      }),

      domAppend(
        domMapComponent(
          ".pl_icon",
          root,
          /*                                                 */
          (e) => e || domPatternIcon(dataMap[props.arrowType.value].icon)
        )
      ),
      domAddListener("click", data.onClick)
    );
  },
});
